import React from 'react';
import { styled } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';
import { classes } from '@guest-widgets/shared/src/classes';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded';
import { LabelTypeDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { Text } from '@eo-locale/react';

import { useProduct } from '../../productContext';

export interface LabelProps {
  withIcon?: boolean;
}

export const Label = ({ withIcon = false, ...chipProps }: LabelProps & ChipProps) => {
  const {
    product: {
      isValid,
      value: { label },
    },
  } = useProduct();

  if (!isValid || !label) {
    return null;
  }

  return (
    <LabelChip
      className={mapToClassName(label.type)}
      labelType={label.type}
      label={<Text id={`product-list-item.labels.${label.type}`} />}
      icon={withIcon ? mapToLabelIcon(label.type) : undefined}
      {...chipProps}
    />
  );
};

const mapToClassName = (labelType: LabelTypeDto): string | undefined =>
  (({
    [LabelTypeDto.SoldOut]: classes.details.labels.soldOut,
    [LabelTypeDto.SaleEnded]: classes.details.labels.saleEnded,
    [LabelTypeDto.New]: classes.details.labels.new,
    [LabelTypeDto.Bestseller]: classes.details.labels.bestseller,
  } as { [key: string]: string })[labelType]);

const mapToLabelIcon = (labelType: LabelTypeDto): JSX.Element | undefined =>
  (({
    [LabelTypeDto.SoldOut]: <OfflineBoltIcon />,
    [LabelTypeDto.SaleEnded]: <OfflineBoltIcon />,
    [LabelTypeDto.New]: <StarsRoundedIcon />,
    [LabelTypeDto.Bestseller]: <WhatshotRoundedIcon />,
  } as { [key: string]: JSX.Element })[labelType]);

type LabelChipProps = {
  labelType: LabelTypeDto;
} & ChipProps;

const LabelChip = styled(({ labelType, ...rest }: LabelChipProps) => <Chip {...rest} />)(
  ({ labelType, theme: { palette } }) => {
    const backgroundColor = [LabelTypeDto.SaleEnded, LabelTypeDto.SoldOut].includes(labelType)
      ? palette.error.main
      : palette.warning.main;
    return {
      backgroundColor,
      color:
        palette.mode === 'light' ? palette.text.primary : palette.getContrastText(backgroundColor),
    };
  }
);
