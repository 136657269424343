import React, { PropsWithChildren, useEffect, useState } from 'react';
import type { DetailProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios/dist/api';
import { Loadable } from '@guest-widgets/shared/src/utils/loadable/loadable';

import { productContext } from '../App/productContext';

export type PreviewProductProviderProps = {
  product: DetailProductDto;
};

export const PreviewProductProvider = ({
  product,
  children,
}: PropsWithChildren<PreviewProductProviderProps>) => {
  const [productLoadable, setProductLoadable] = useState<Loadable<DetailProductDto>>(
    Loadable.createEmpty()
  );

  useEffect(() => {
    // Can't provide product before translations. Need to be properly fixed.
    setTimeout(() => setProductLoadable(Loadable.createValid(product)), 300);
  }, []);

  const value = {
    product: productLoadable,
  };

  return <productContext.Provider value={value}>{children}</productContext.Provider>;
};

export const PreviewProductConsumer = productContext.Consumer;
