import React from 'react';
import { useWidget } from '@guest-widgets/core';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { classes } from '@guest-widgets/shared/src/classes';

export const ReturnSkeleton = () => {
  const { widgetArea } = useWidget();

  return widgetArea.isSmall ? (
    <ReturnMobile className={classes.details.backLink.mobile}>
      <Skeleton variant="rectangular" width={120} height={16} />
    </ReturnMobile>
  ) : (
    <Skeleton
      className={classes.details.backLink.desktop}
      variant="rectangular"
      width={60}
      height={20}
    />
  );
};

const ReturnMobile = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  padding: spacing(1, 0, 8),
  alignItems: 'center',
  height: 60,
  marginBottom: spacing(4),
}));
