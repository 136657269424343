import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { customerTracking } from '../customerTracking';

export function handleTrackingEvent(eventType: TrackingEventType, data: any) {
  const event = customerTracking()[eventType];
  if (event) {
    event(data);
  } else {
    console.error(`No event found for this type: ${eventType}`);
  }
}
