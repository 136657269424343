import React, { useState } from 'react';
import type { ReviewDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { classes } from '@guest-widgets/shared/src/classes';
import { Rating } from '@guest-widgets/shared/src/components/Rating/Rating';
import { LineClamp } from '@guest-widgets/shared/src/components/LineClamp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { styled as muiStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DateTime } from '@eo-locale/react';

import { ReviewContent } from './ReviewContent';
import { CategoryTitle } from './CategoryTitle';

export interface ReviewsVerticalPreviewProps {
  reviews: ReviewDto[];
}

interface RatingStarsProps {
  value: number;
  category: string;
}

const RatingStars = ({ value, category }: RatingStarsProps) => (
  <>
    <Rating value={value * 5} noLabel />
    <Typography
      className={classes.details.reviews.rating.category.label}
      variant="caption"
      color="textSecondary"
    >
      <CategoryTitle label={category} />
    </Typography>
  </>
);

export const ReviewsHorizontalPreview = ({ reviews }: ReviewsVerticalPreviewProps) => {
  const [showMore, setShowMore] = useState(false);
  let isSeeMoreVisible;

  return (
    <Outer className={classes.details.reviews.carousel}>
      <Inner>
        {reviews.map(({ votes, author, createdAt, title, content, id, replies }) => {
          const overallRating = votes.find(({ category }) => category === 'Overall rating');
          const reviewsLength = reviews.length;
          const isAllRatingsVisible = reviewsLength === 1 && !content;
          isSeeMoreVisible = reviewsLength === 1 && content.length !== 0;

          return (
            <BorderBox
              className={classes.details.reviews.review.root}
              key={id}
              isSeeMoreVisible={isSeeMoreVisible}
            >
              <Box display="flex" mb={2}>
                <Box mr={2} className={classes.details.reviews.rating.stars}>
                  {overallRating && ((isSeeMoreVisible && !showMore) || reviewsLength !== 1) && (
                    <RatingStars value={overallRating.value} category={overallRating.category} />
                  )}
                  {(isAllRatingsVisible || showMore) &&
                    votes.map((rating, index) => (
                      <RatingStars key={index} value={rating.value} category={rating.category} />
                    ))}
                </Box>
                <CaptionWrapper className={classes.details.reviews.review.caption}>
                  <p>
                    <Typography variant="caption" color="textSecondary">
                      {author.name}
                    </Typography>
                  </p>
                  <p>
                    <Typography variant="caption" color="textSecondary">
                      <DateTime value={new Date(createdAt)} />
                    </Typography>
                  </p>
                </CaptionWrapper>
              </Box>
              <Typography
                className={classes.details.reviews.review.header}
                variant="h5"
                gutterBottom
              >
                {title}
              </Typography>
              <LineClamp lines={showMore ? 200 : 5} className={classes.details.reviews.review.body}>
                {content}
              </LineClamp>
              <Box className={classes.details.reviews.review.replies} ml={2} mt={2}>
                {showMore &&
                  replies.length > 0 &&
                  replies.map(({ author: { name }, content, createdAt }) => (
                    <ReviewContent
                      key={createdAt}
                      author={name}
                      createdAt={createdAt}
                      content={content}
                    />
                  ))}
              </Box>
              {isSeeMoreVisible && (
                <LineClampExpandBtn onClick={() => setShowMore(!showMore)}>
                  <Text id={`product-details.reviews.${showMore ? 'show-less' : 'show-more'}`} />
                  {showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </LineClampExpandBtn>
              )}
            </BorderBox>
          );
        })}
      </Inner>
    </Outer>
  );
};

export const Outer = styled('div')(({ theme, widgetArea }) => ({
  margin: `0 -${theme.spacing(widgetArea.basedOnSize(4, 6, 8))}px`,
  overflowX: 'auto',
}));

export const Inner = styled('div')(({ theme, widgetArea }) => ({
  padding: `0 ${theme.spacing(widgetArea.basedOnSize(4, 6, 8))}px`,
  display: 'flex',
  float: 'left',
}));

export const LineClampExpandBtn = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  cursor: 'pointer',
}));

export const CaptionWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-1),
}));

type BorderBoxProps = {
  isSeeMoreVisible: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const BorderBox = muiStyled(({ isSeeMoreVisible, ...props }: BorderBoxProps) => (
  <div {...props} />
))(({ theme: { spacing, border, shape, palette }, isSeeMoreVisible }) => ({
  '&:not(:last-child)': {
    marginRight: spacing(3),
  },
  width: isSeeMoreVisible ? '100%' : '288px',
  flexShrink: 0,
  border: border,
  borderRadius: shape.borderRadius,
  padding: spacing(3, 4),
  backgroundColor: palette.background.default,
}));
