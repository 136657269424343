import { customerTrackingCreator, Tag } from './customerTrackingCreator';
import { filterAvailableTags } from './utils/filterAvailableTags';
import { GTM } from './gtm';
import { GA4EventMapper } from './mappers/ga4/ga4EventsMapper';
import { Datadog } from './datadog';
import { Matomo } from './matomo';

//tracker instance with their own mappers
const ga4EventMapper = new GA4EventMapper();
const gtmTracker = new GTM(ga4EventMapper);
const datadogTracker = new Datadog(ga4EventMapper);
const matomoTracker = Matomo.getInstance();
const tags = [gtmTracker, matomoTracker, datadogTracker];

export const customerTracking = () => customerTrackingCreator(filterAvailableTags(tags));
