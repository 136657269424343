import { Box, Skeleton } from '@mui/material';

export const BasePriceSkeleton = () => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" pb={7} pt={3}>
      <Skeleton variant="text" width={140} height={60} />
      <Skeleton variant="text" width={120} height={48} />
    </Box>
  );
};
