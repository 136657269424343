import React from 'react';
import { Box, Typography } from '@mui/material';
import { DetailProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios/dist/api';
import { styled, useWidget } from '@guest-widgets/core';
import Button from '@mui/material/Button';
import { Text } from '@eo-locale/react';

export type BookingPreviewProps = {
  product: DetailProductDto;
};

export const BookingPreview = ({}: BookingPreviewProps) => {
  const { widgetArea } = useWidget();
  // Todo: render booking preview instead

  const mobileBooking = (
    <SelectDateAndBook variant="contained" color="primary" fullWidth size="large" disableElevation>
      <Text id="product-details.booking.select-date-and-book" />
    </SelectDateAndBook>
  );

  const desktopBooking = (
    <Box
      mt={8}
      height={300}
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      bgcolor="#eee"
    >
      <Typography align="center">
        Booking Preview
        <br />
        (Coming soon)
      </Typography>
    </Box>
  );

  return widgetArea.basedOnSize(mobileBooking, desktopBooking);
};

const SelectDateAndBook = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  marginTop: theme.spacing(4),
  color: theme.palette.background.paper,
}));
