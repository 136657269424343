import React from 'react';
import { Text } from '@eo-locale/react';
import Typography from '@mui/material/Typography';
import { classes } from '@guest-widgets/shared/src/classes';
import { Feature } from '@guest-widgets/shared/src/features';
import { ProductLink } from '@guest-widgets/shared/src/components/Router/ProductLink';
import { ProductListItem } from '@guest-widgets/shared/src/components/ProductListItem/ProductListItem';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { styled, useWidget } from '@guest-widgets/core';

import { useCrossSelling } from '../crossSellingContext';

const CrossSelling = () => {
  const products = useCrossSelling();
  const { widgetArea } = useWidget();
  const { isEnabled } = useFeature();

  if (!products.isValid || products.value.length === 0 || !isEnabled(Feature.CrossSelling))
    return null;

  return (
    <div className={classes.details.crossSelling.root}>
      <Title variant="h2" className={classes.details.crossSelling.header}>
        <Text id={'product-details.cross-selling.title'} length={products.value.length} />
      </Title>
      <ItemsWrapper className={classes.details.crossSelling.list}>
        <Items>
          {products.value
            // Todo: remove this filtering after it is done and deployed on Spine side.
            .filter(({ price }) => price.gross.amount !== null)
            .map(({ id, title, description, price, images, rating, locations, duration }) => (
              <ItemWrapper key={id} className={classes.details.crossSelling.item}>
                <ProductLink id={id}>
                  <ProductListItem
                    title={title}
                    description={widgetArea.isLarge ? description : undefined}
                    rating={rating}
                    price={price}
                    image={images[0]?.url}
                    small={!widgetArea.isLarge}
                    location={locations[0]?.city}
                    duration={duration}
                    isEnabled={isEnabled}
                  />
                </ProductLink>
              </ItemWrapper>
            ))}
        </Items>
      </ItemsWrapper>
    </div>
  );
};

const customSpacing = 4;

export const ItemsWrapper = styled('div')(({ theme, widgetArea }) => ({
  margin: `-${theme.spacing(customSpacing)} -${theme.spacing(widgetArea.basedOnSize(4, 6, 8))}`,
  overflowX: 'auto',
  overflowY: 'hidden',
}));

export const Items = styled('div')(({ theme, widgetArea }) => ({
  padding: `${theme.spacing(customSpacing)} ${theme.spacing(widgetArea.basedOnSize(4, 6, 8))}`,
  display: 'flex',
  float: 'left',
}));

const Title = styled(Typography)(({ theme: { spacing, typography }, widgetArea }) => ({
  fontSize: widgetArea.basedOnSize(
    typography.h4.fontSize,
    typography.h4.fontSize,
    typography.h2.fontSize
  ),
  padding: `${spacing(customSpacing)} 0`,
}));

export const ItemWrapper = styled('div')(({ theme: { spacing }, widgetArea }) => ({
  '&:not(:last-child)': {
    marginRight: spacing(widgetArea.basedOnSize(3, 4, 5)),
  },
  width: spacing(widgetArea.basedOnSize(69, 69, 84)),
  display: 'inline-block',
}));

export default CrossSelling;
