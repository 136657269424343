export const getVideoId = (url: string): string | null => {
  const result = /(?:(?:[?&])v=|\/)?([A-Za-z0-9-_]{11})/.exec(url);
  if (!result) return null;
  return result[1] || null;
};

export const getPreviewUrl = (videoId: string): string =>
  `https://img.youtube.com/vi/${videoId}/sddefault.jpg`; // sddefault 640x480, hqdefault 480x360

export const getEmbedUrl = (videoId: string): string =>
  `https://www.youtube.com/embed/${videoId}?autoplay=1&modestbranding=0&rel=0`;
