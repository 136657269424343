import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import {
  FilterAttributeDto,
  FilterAttributeOption,
  FilterAttributeCodeDto,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { Loadable, useLoadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { productApi } from '@guest-widgets/shared/src/apis/guestExperience';

import { useSettings } from './settingsContext';
import { CategoryCode, PriceRange, defaultMaxPriceRange, defaultCurrency } from './filters';

export interface Attributes {
  categoryOptions: FilterAttributeOption[];
  categoryCode: CategoryCode;
  maxPriceRange: PriceRange;
  currency: string;
  miscOptions: FilterAttributeOption[];
  availableLanguages: FilterAttributeOption[];
}

interface FilterAttributesContext {
  attributes: Loadable<Attributes>;
  loadAttributes(): void;
}

const defaultFilterAttributesContextValue: FilterAttributesContext = {
  attributes: Loadable.createEmpty<Attributes>(),
  loadAttributes: () => {},
};

export const filterAttributesContext = createContext<FilterAttributesContext>(
  defaultFilterAttributesContextValue
);

export const FilterAttributesProvider = ({ children }: PropsWithChildren<{}>) => {
  const { customerCode, locale } = useSettings();
  const [attributes, loadFilterAttributes] = useLoadable(() =>
    productApi.getFilterAttributes(customerCode, locale)
  );

  useEffect(() => {
    loadFilterAttributes();
  }, [customerCode, locale]);

  const value: FilterAttributesContext = {
    attributes: attributes.map(mapAttributes),
    loadAttributes: loadFilterAttributes,
  };

  return (
    <filterAttributesContext.Provider value={value}>{children}</filterAttributesContext.Provider>
  );
};

export const useFilterAttributes = () => useContext(filterAttributesContext);

const extractCategories = (attributes: FilterAttributeDto[]) => {
  const categories =
    attributes.find((attr) => attr.code === FilterAttributeCodeDto.Categories) ||
    attributes.find((attr) => attr.code === FilterAttributeCodeDto.Tags);
  const code = (categories?.code as CategoryCode) || FilterAttributeCodeDto.Tags;
  const options = categories?.options || [];
  return { code, options };
};

const extractPriceRangeAndCurrency = (attributes: FilterAttributeDto[]) => {
  const price = attributes.find((attr) => attr.code === FilterAttributeCodeDto.Price);
  // todo: remove "as any" after Spine client is updated
  const currency = (price as any)?.currency || defaultCurrency;
  const priceOptions = price?.options || [];
  const min = parseInt(priceOptions[0]?.value.split(',')[0]) ?? defaultMaxPriceRange[0];
  const max =
    parseInt(priceOptions[priceOptions.length - 1]?.value.split(',')[1]) ?? defaultMaxPriceRange[1];
  return {
    range: [min, max] as PriceRange,
    currency,
  };
};

const extractTopThingsToDo = (attributes: FilterAttributeDto[]) =>
  attributes.filter((attr) => attr.code === FilterAttributeCodeDto.TopThingsToDo)[0]?.options || [];

const extractAvailableLanguages = (attributes: FilterAttributeDto[]) => {
  return attributes.find((attr) => attr.code === FilterAttributeCodeDto.Languages)?.options || [];
};

const mapAttributes = (attributes: FilterAttributeDto[]): Attributes => {
  const { options, code } = extractCategories(attributes);
  const { range, currency } = extractPriceRangeAndCurrency(attributes);

  return {
    maxPriceRange: range,
    currency,
    categoryOptions: options,
    categoryCode: code,
    miscOptions: extractTopThingsToDo(attributes),
    availableLanguages: extractAvailableLanguages(attributes),
  };
};
