import React from 'react';
import { styled } from '@mui/material/styles';
import {
  LabelDto,
  LabelTypeDto,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { classes } from '@guest-widgets/shared/src/classes';
import { Text } from '@eo-locale/react';

export interface LabelProps {
  label?: LabelDto;
}

export const Label = ({ label }: LabelProps) =>
  label ? (
    <LabelChip className={mapToClassName(label.type)}>
      <Text id={`product-list-item.labels.${label.type}`} />
    </LabelChip>
  ) : null;

const mapToClassName = (labelType: LabelTypeDto): string | undefined =>
  (({
    [LabelTypeDto.SoldOut]: classes.catalog.product.labels.soldOut,
    [LabelTypeDto.SaleEnded]: classes.catalog.product.labels.saleEnded,
    [LabelTypeDto.New]: classes.catalog.product.labels.new,
    [LabelTypeDto.Bestseller]: classes.catalog.product.labels.bestseller,
  } as { [key: string]: string })[labelType]);

const LabelChip = styled('div')(({ theme: { spacing, typography, palette } }) => ({
  zIndex: 10,
  position: 'absolute',
  top: spacing(5),
  padding: spacing(1, 2, 1, 3),
  fontSize: typography.caption.fontSize,
  height: '1.825em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontWeight: typography.fontWeightMedium,
  backgroundColor: palette.warning.main,
  color: palette.mode === 'light' ? palette.text.primary : palette.warning.contrastText,
  textTransform: 'uppercase',
  '&::after': {
    content: '""',
    borderLeft: '0px solid transparent',
    //borders has been harcoded as this used to generate slides for tags
    borderRight: `0.95em solid transparent`,
    borderBottom: `1.825em solid ${palette.warning.main}`,
    position: 'absolute',
    top: '0px',
    left: '100%',
  },
}));
