import Box from '@mui/material/Box';
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import { classes } from '../../classes';

import { RatingStars } from './RatingStars';
import { RatingStar, RatingStarFill } from './RatingStar';

export interface RatingProps {
  /**
   * In [0, 5]
   */
  value: number;
  count?: number;
  countLabel?: string;
  small?: boolean;
  noLabel?: boolean;
}

export const Rating = ({ value, count, countLabel, small, noLabel = false }: RatingProps) => {
  const { palette } = useTheme();
  return (
    <Box className={classes.catalog.product.rating} display="flex" alignItems="center">
      {small && (
        <RatingStar
          filledColor={palette.warning.main}
          emptyColor={palette.info.main}
          fill={RatingStarFill.Filled}
        />
      )}
      {!small && (
        <RatingStars
          filledColor={palette.warning.main}
          emptyColor={palette.info.main}
          value={value}
        />
      )}
      {!noLabel && (
        <Label>
          {value.toFixed(1)}
          {count ? ` (${small ? count : countLabel})` : null}
        </Label>
      )}
    </Box>
  );
};

const Label = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
  color: theme.palette.text.primary,
}));
