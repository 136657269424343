import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useWidget, styled } from '@guest-widgets/core';
import { ProductListItemSkeleton } from '@guest-widgets/shared/src/components/ProductListItem/ProductListItemSkeleton';

export const CatalogSkeleton = () => {
  const { widgetArea } = useWidget();
  const defaultProductSkeletons = widgetArea.isLarge ? 3 : 2;

  const renderProductSkeletons = () =>
    Array.from({ length: defaultProductSkeletons }).map((value, index) => (
      <ItemWrapper key={`item-wrapper-${index}`}>
        <ProductListItemSkeleton />
      </ItemWrapper>
    ));

  return (
    <Container>
      <Box display="flex" justifyContent="space-between">
        <Skeleton variant="rectangular" width={150} height={30} />
      </Box>
      <ProductItemContainer>{renderProductSkeletons().map((node) => node)}</ProductItemContainer>
    </Container>
  );
};

const Container = styled('div')(({ theme, widgetArea }) => ({
  padding: theme.spacing(widgetArea?.basedOnSize(6, 8, 10)),
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
}));

const ProductItemContainer = styled('div')({
  margin: '8px -20px 0px -20px',
  display: 'flex',
  flexWrap: 'wrap',
});

const ItemWrapper = styled('div')(({ theme: { spacing }, widgetArea }) => {
  const imageWidth = THUMBNAIL_WIDTH;
  const largeScreenSpacing = 5;
  return {
    padding: spacing(widgetArea.basedOnSize(3, 4, largeScreenSpacing)),
    width: widgetArea.basedOnSize('100%', '50%', '33%'),
    maxWidth: imageWidth, // prevents the images from stretching
  };
});

const THUMBNAIL_WIDTH = 400;
