import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export interface BookingSkeletonProps {
  showHeader?: boolean;
}

export const BookingSkeleton = ({ showHeader = true }: BookingSkeletonProps) => (
  <div>
    {showHeader && (
      <HeaderSkeleton>
        <Skeleton variant="text" width={150} height={40} />
        <Box>
          <Skeleton variant="text" width={50} height={20} />
          <Skeleton variant="text" width={80} height={30} />
        </Box>
      </HeaderSkeleton>
    )}
    <BookingWidgetSkeleton>
      <Box my={8}>
        <Skeleton variant="text" width={150} height={40} />
      </Box>
      <SkeletonItem variant="text" height={40} />
      <SkeletonItem variant="text" width={150} height={40} />
      <SkeletonItem variant="text" height={40} />
      <SkeletonItem variant="text" width={150} height={40} />
      <SkeletonItem variant="text" height={40} />
    </BookingWidgetSkeleton>
  </div>
);

const HeaderSkeleton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(7),
  marginTop: theme.spacing(4),
}));

const BookingWidgetSkeleton = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.common.white,
}));

const SkeletonItem = styled(Skeleton)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));
