import React from 'react';

export interface RatingStarProps {
  fill: RatingStarFill;
  filledColor?: string;
  emptyColor?: string;
  emptyOpacity?: number;
}

export enum RatingStarFill {
  Filled = 'Filled',
  Half = 'Half',
  Empty = 'Empty',
}

export const RatingStar = ({
  fill,
  emptyColor,
  filledColor,
  emptyOpacity = 1,
}: RatingStarProps) => {
  const fullPath =
    'M12 15.8501L15.28 18.2368C15.8 18.6235 16.5067 18.1035 16.3067 17.4901L15.0533 13.6368L18.2933 11.3301C18.8267 10.9568 18.56 10.1168 17.9067 10.1168H13.9333L12.64 6.09013C12.44 5.47679 11.5733 5.47679 11.3733 6.09013L10.0667 10.1168H6.09334C5.44 10.1168 5.17334 10.9568 5.70667 11.3301L8.94667 13.6368L7.69334 17.4901C7.49334 18.1035 8.2 18.6101 8.72 18.2368L12 15.8501Z';

  return (
    <svg width="1em" height="1em" viewBox="5.42 5.63 13.16 12.74">
      {(fill === RatingStarFill.Empty || fill === RatingStarFill.Half) && (
        <path
          data-testid="rating-star-empty"
          fill={emptyColor}
          opacity={emptyOpacity}
          d={fullPath}
        />
      )}
      {fill === RatingStarFill.Filled && (
        <path data-testid="rating-star-filled" fill={filledColor} d={fullPath} />
      )}
      {fill === RatingStarFill.Half && (
        <path
          data-testid="rating-star-half"
          fill={filledColor}
          d="M12 5.63013C11.7356 5.63266 11.4725 5.78598 11.3733 6.09009L10.0667 10.1168H6.09334C5.44 10.1168 5.17334 10.9568 5.70667 11.3301L8.94667 13.6368L7.69334 17.4901C7.49334 18.1034 8.2 18.6101 8.72 18.2368L12 15.8501L12 5.63013Z"
        />
      )}
    </svg>
  );
};
