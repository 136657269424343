import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslator } from '@eo-locale/react';
import { classes } from '@guest-widgets/shared/src/classes';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { Feature } from '@guest-widgets/shared/src/features';
import { Rating } from '@guest-widgets/shared/src/components/Rating/Rating';
import { useWidget, styled } from '@guest-widgets/core';

import { useProduct } from '../../productContext';

import { MediaGallery } from './MediaGallery/MediaGallery';
import { Label } from './Label';
import { Covid19 } from './Covid19';
import { Duration } from './Duration';
import { Location } from './Location';

export const ProductHead = () => {
  const { value: product } = useProduct().product;
  const { title, rating, images, videos, information, highlights } = product;
  const { translate } = useTranslator();
  const { widgetArea } = useWidget();
  const { isEnabled } = useFeature();

  const components: { [key: string]: ReactNode } = {
    title: (
      <TitleContainer>
        <Title className={classes.details.header} variant="h1">
          {title}
        </Title>
        {!widgetArea.isSmall && isEnabled(Feature.Tags) && (
          <Labels className={classes.details.headerLabels}>
            <Label />
            <Covid19 />
            <Duration />
          </Labels>
        )}
      </TitleContainer>
    ),
    tags: (
      <Tags className={classes.details.tags}>
        {rating && rating.count > 0 && isEnabled(Feature.RatingAndReview) && (
          <StyledRating
            className={classes.details.labels.rating}
            value={rating.value * 5}
            count={rating.count}
            small={widgetArea.isSmall}
            countLabel={translate('product-details.reviews.title', {
              length: rating.count,
            })}
          />
        )}
        {widgetArea.isSmall && isEnabled(Feature.Tags) && (
          <>
            <Label withIcon />
            <Covid19 />
            <Duration />
          </>
        )}
        <Location />
      </Tags>
    ),
    mediaGallery: (
      <Gallery className={classes.details.gallery.root}>
        <MediaGallery images={images} videos={videos} />
      </Gallery>
    ),
    showDescription: isEnabled(Feature.ShowDescription) && (
      <Box className={classes.details.showDescription}>
        <DescriptionText dangerouslySetInnerHTML={{ __html: information.description.body }} />
        {highlights ? (
          <HighlightList className={classes.details.highlights}>
            {(highlights as string[]).map((highlight, i) => (
              <li key={i} dangerouslySetInnerHTML={{ __html: highlight }} />
            ))}
          </HighlightList>
        ) : null}
      </Box>
    ),
  };

  const order = widgetArea.isSmall
    ? ['mediaGallery', 'title', 'showDescription', 'tags']
    : ['title', 'tags', 'mediaGallery', 'showDescription'];

  return <StyledHead>{order.map((key) => components[key])}</StyledHead>;
};

export const StyledHead = styled('div')(({ theme: { spacing, border } }) => ({
  paddingBottom: spacing(4),
  borderBottom: border,
  '& > *': {
    margin: spacing(4, 0),
  },
}));

const DescriptionText = styled(Typography)({
  '& p': {
    marginBlockStart: 'unset',
    marginBlockEnd: 'unset',
  },
});

const Gallery = styled('div')(({ theme, widgetArea }) => ({
  margin: widgetArea.isSmall ? theme.spacing(-4) : undefined,
  marginBottom: 0,
}));

export const Tags = styled('div')(({ theme: { spacing, palette, typography }, widgetArea }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  ...widgetArea.basedOnSize(
    {
      alignContent: 'center',
      minHeight: '50px',
      padding: spacing(2, 4),
      margin: `0 -${spacing(4)}`,
      marginBottom: 0,
    },
    {
      padding: spacing(0, 1),
    }
  ),
  '& > *': {
    marginRight: spacing(4),
  },
  '& [class*="MuiChip-root"]': {
    backgroundColor: 'transparent',
    fontSize: typography.h5.fontSize,
    fontWeight: typography.fontWeightRegular,
    '& [class*="MuiChip-icon"]': {
      marginLeft: 0,
    },
    '& [class*="MuiChip-label"]': {
      paddingRight: 0,
    },
  },
}));

const HighlightList = styled('ul')(({ theme: { spacing, typography } }) => ({
  fontSize: typography.body2.fontSize,
  marginTop: spacing(2),
  listStyleType: 'disc',
  paddingLeft: spacing(5),
  '&>li': {
    marginTop: spacing(1),
  },
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const TitleContainer = styled('div')(({ widgetArea }) => ({
  ...(widgetArea.isMedium
    ? {
        display: 'flex',
        flexDirection: 'column-reverse',
      }
    : undefined),
}));

const Title = styled(Typography)(({ theme: { spacing, typography }, widgetArea }) => ({
  display: 'inline',
  marginRight: spacing(6),
  fontSize: widgetArea.basedOnSize(
    typography.h2.fontSize,
    typography.h2.fontSize,
    typography.h1.fontSize
  ),
}));

const Labels = styled('div')(({ theme: { typography, spacing }, widgetArea }) => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  whiteSpace: 'nowrap',
  verticalAlign: 'text-bottom',
  '& [class*="MuiChip-root"]': {
    textTransform: 'uppercase',
    fontWeight: typography.fontWeightBold,
    fontSize: typography.h6.fontSize,
    height: '2.8em',
    borderRadius: '1.375em',
    margin: widgetArea.basedOnSize(0, spacing(0, 2, 2, 0), spacing(2, 2, 0, 0)),
  },
  '& [class*="MuiChip-label"]': {
    paddingRight: '0.75em',
    paddingLeft: '0.75em',
  },
}));
