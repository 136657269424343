import React from 'react';
import { Helmet } from 'react-helmet';

interface GoogleMapsApiLoaderProps {
  apiKey: string;
  // comma-separated libraries (see: https://developers.google.com/maps/documentation/javascript/libraries)
  libraries?: string;
  language?: string;
  region?: string;
}

export const GoogleMapsApiLoader = ({
  apiKey,
  libraries,
  language,
  region,
}: GoogleMapsApiLoaderProps) => {
  const params = new URLSearchParams({
    key: apiKey,
  });

  if (libraries) {
    params.append('libraries', libraries);
  }

  if (language) {
    params.append('language', language);
  }

  if (region) {
    params.append('region', region);
  }

  if (window.google?.maps) return null;

  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          src: 'https://maps.googleapis.com/maps/api/js?' + params.toString(),
          defer: true,
        },
      ]}
    />
  );
};
