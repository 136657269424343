import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';

import { RatingStar, RatingStarFill } from './RatingStar';

export interface RatingStarsProps {
  /**
   * In [0, 5]
   */
  value: number;
  filledColor?: string;
  emptyColor?: string;
}

export const RatingStars = ({ value, filledColor, emptyColor }: RatingStarsProps) => (
  <Stars>
    {Array(5)
      .fill(null)
      .map((_, index) => {
        const fill = (() => {
          if (value <= index + 0.25) return RatingStarFill.Empty;
          if (value > index + 0.25 && value < index + 0.75) return RatingStarFill.Half;
          return RatingStarFill.Filled;
        })();
        return (
          <RatingStar filledColor={filledColor} emptyColor={emptyColor} key={index} fill={fill} />
        );
      })}
  </Stars>
);

const Stars = styled('div')(({ theme }) => ({
  lineHeight: 0,
  '& > svg': {
    marginRight: theme.spacing(1),
  },
}));
