import React from 'react';
import { QueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { FeatureProvider } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { ConfigurationTheme } from '@guest-widgets/shared/src/components/ConfigurationTheme/ConfigurationTheme';
import { DetailProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { useWidget } from '@guest-widgets/core';

import { LocalizationProvider } from '../App/LocalizationProvider';
import { ProductDetails } from '../App/ProductDetails/ProductDetails';

import {
  PreviewSettingsConsumer,
  PreviewSettingsProviderProps,
  PreviewSettingsProvider,
} from './PreviewSettingsProvider';
import { PreviewReviewsProvider } from './PreviewReviewsProvider';
import { PreviewCrossSellingProvider } from './PreviewCrossSellingProvider';
import { PreviewProductConsumer, PreviewProductProvider } from './PreviewProductProvider';
import { BookingPreview } from './BookingPreview';

interface ProductPreviewWidgetElement extends HTMLElement {
  product?: DetailProductDto;
}

export interface PreviewAppProps extends PreviewSettingsProviderProps {}

export const PreviewApp = (props: PreviewAppProps) => {
  const widget = useWidget();
  const element: ProductPreviewWidgetElement = widget.element;

  if (!element.product) return null;

  return (
    <QueryRouter>
      <PreviewSettingsProvider {...props}>
        <PreviewSettingsConsumer>
          {({ disableFeatures, enableFeatures, configuration, disableDownloadFont }) => (
            <LocalizationProvider>
              <FeatureProvider disable={disableFeatures} enable={enableFeatures}>
                <ConfigurationTheme
                  configuration={configuration}
                  disableDownloadFont={disableDownloadFont}
                >
                  {element.product && (
                    <PreviewProductProvider product={element.product}>
                      <PreviewProductConsumer>
                        {({ product }) => (
                          <PreviewReviewsProvider>
                            <PreviewCrossSellingProvider>
                              <ProductDetails
                                bookingComponent={() =>
                                  product.value && <BookingPreview product={product.value} />
                                }
                              />
                            </PreviewCrossSellingProvider>
                          </PreviewReviewsProvider>
                        )}
                      </PreviewProductConsumer>
                    </PreviewProductProvider>
                  )}
                </ConfigurationTheme>
              </FeatureProvider>
            </LocalizationProvider>
          )}
        </PreviewSettingsConsumer>
      </PreviewSettingsProvider>
    </QueryRouter>
  );
};
