import React, { ReactNode } from 'react';
import { Text } from '@eo-locale/react';
import Box from '@mui/material/Box';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { DurationDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { classes } from '@guest-widgets/shared/src/classes';
import { Expandable } from '@guest-widgets/shared/src/components/Expandable/Expandable';
import { Feature } from '@guest-widgets/shared/src/features';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { Covid19Icon } from '@guest-widgets/shared/src/components/Icons/Covid19Icon';
import { mapDurationToLocalString } from '@guest-widgets/shared/src/mappers/durationMapper';

import { useProduct } from '../../productContext';

import { InformationItem } from './InformationItem';

export const Information = () => {
  const { value: product } = useProduct().product;
  const { isEnabled } = useFeature();
  const { attachments, information, duration } = product;
  const {
    services,
    notIncluded,
    equipmentAndClothing,
    participants,
    languages,
    openingHours,
    importantInfo: requirements, // "Requirements" on the UI
    otherInfo: importantInfo, // "Important information" on the UI
  } = information;
  // todo: use `product.covid19Safe` to render the covid item after Spine is deployed
  const covid19Safe = information.covid19Safety?.body === '1';
  // todo: use `product.spectatorsAllowed` to render the spectators item (new one) after Spine is deployed

  const getAllDurations = (duration: DurationDto[]): ReactNode =>
    duration.map((value, index) => (
      <>
        <Text
          id={'product-list-item.duration.' + mapDurationToLocalString(value.unit)}
          count={value.value}
        />
        {index !== duration.length - 1 && <>, </>}
      </>
    ));

  const items = [
    covid19Safe && isEnabled(Feature.InfoCovid) && (
      <InformationItem
        className={classes.details.information.covid19}
        icon={<Covid19Icon />}
        title={<Text id="product-details.information.covid-19-safety.title" />}
        content={<Text id="product-details.information.covid-19-safety.description" />}
      />
    ),
    services && isEnabled(Feature.InfoIncluded) && (
      <InformationItem
        className={classes.details.information.services}
        icon={<CheckCircleOutlineRoundedIcon />}
        title={<Text id="product-details.information.services" />}
        html={services.body}
      />
    ),
    notIncluded && (
      <InformationItem
        className={classes.details.information.notIncluded}
        icon={<RadioButtonUncheckedRoundedIcon />}
        title={<Text id="product-details.information.not-included" />}
        html={notIncluded.body}
      />
    ),
    duration && duration.length > 1 && (
      <InformationItem
        className={classes.details.information.duration}
        icon={<QueryBuilderRoundedIcon />}
        title={<Text id="product-list-item.duration.title" />}
        content={getAllDurations(duration)}
      />
    ),
    equipmentAndClothing && isEnabled(Feature.InfoBringWithYou) && (
      <InformationItem
        className={classes.details.information.equipment}
        icon={<PlaylistAddCheckRoundedIcon />}
        title={<Text id="product-details.information.equipment-and-clothing" />}
        html={equipmentAndClothing.body}
      />
    ),
    participants && isEnabled(Feature.InfoParticipants) && (
      <InformationItem
        className={classes.details.information.participants}
        icon={<PeopleOutlineRoundedIcon />}
        title={<Text id="product-details.information.participants" />}
        html={participants.body}
      />
    ),
    languages && isEnabled(Feature.InfoLanguages) && (
      <InformationItem
        className={classes.details.information.languages}
        icon={<LanguageRoundedIcon />}
        title={<Text id="product-details.information.languages" />}
        html={languages.body}
      />
    ),
    openingHours && isEnabled(Feature.InfoOpeningHours) && (
      <InformationItem
        className={classes.details.information.openingHours}
        icon={<QueryBuilderRoundedIcon />}
        title={<Text id="product-details.information.opening-hours" />}
        html={openingHours.body}
      />
    ),
    requirements && isEnabled(Feature.InfoRequirements) && (
      <InformationItem
        className={classes.details.information.requirements}
        icon={<ListAltIcon />}
        title={<Text id="product-details.information.requirements" />}
        html={requirements.body}
      />
    ),
    importantInfo && isEnabled(Feature.InfoImportantInformation) && (
      <InformationItem
        className={classes.details.information.importantInfo}
        icon={<ErrorOutlineRoundedIcon />}
        title={<Text id="product-details.information.important-info" />}
        html={importantInfo.body}
      />
    ),
    attachments.length > 0 && isEnabled(Feature.InfoAdditional) && (
      <InformationItem
        className={classes.details.information.attachments}
        icon={<SaveAltRoundedIcon />}
        title={<Text id="product-details.information.additional-info" />}
        content={attachments.map(({ url, name }, index) => (
          <p key={index}>
            <a href={url} target="_blank" rel="noreferrer">
              {name}
            </a>
          </p>
        ))}
      />
    ),
  ];

  if (items.every((item) => !item)) return null;

  return (
    <Expandable
      className={classes.details.information.root}
      closedContent={<Text id="product-details.information.hint" />}
      title={<Text id="product-details.information.title" />}
      borderBottom
    >
      <Box pt={2}>{items}</Box>
    </Expandable>
  );
};
