import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useWidget } from '@guest-widgets/core';

import { Item, Footer } from './ProductListItem';

export const ProductListItemSkeleton = () => {
  const { widgetArea } = useWidget();

  const content = {
    title: <Skeleton variant="rectangular" width={150} height={30} />,
    rating: <Skeleton variant="rectangular" width={80} height={25} />,
    description: (
      <Box>
        <Skeleton variant="text" width={200} height={35} />
        <Skeleton variant="text" width={170} height={35} />
      </Box>
    ),
    location: (
      <LocationContainer>
        <Skeleton variant="text" width={100} height={25} />
        <Skeleton variant="text" width={100} height={25} />
      </LocationContainer>
    ),
    price: (
      <Box>
        <Skeleton variant="text" width={50} height={30} />
        <Skeleton variant="rectangular" width={70} height={30} />
      </Box>
    ),
    button: <Skeleton variant="rectangular" width={80} height={40} />,
  };

  const defaultContent = (
    <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow="1" p={4}>
      <div>
        {content.title}
        {content.description}
      </div>
      <div>
        {content.rating}
        {content.location}
        <Footer display="flex" justifyContent="space-between" alignItems="center">
          {content.price}
          {content.button}
        </Footer>
      </div>
    </Box>
  );

  const reducedContent = (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" p={3}>
      <SmallLeftContent pr={3}>
        {content.rating}
        {content.title}
      </SmallLeftContent>
      {content.price}
    </Box>
  );

  return (
    <Item>
      <Skeleton variant="rectangular" width="100%" height={200} />
      {widgetArea.isSmall ? reducedContent : defaultContent}
    </Item>
  );
};

const SmallLeftContent = styled(Box)(({ theme }) => ({
  maxWidth: '75%',

  '& > :first-child': {
    marginBottom: theme.spacing(1),
  },
}));

const LocationContainer = styled('div')(({ theme }) => ({
  display: 'flex',

  '& > *': {
    marginRight: theme.spacing(2),
  },
}));
