import React, { PropsWithChildren } from 'react';
import { useQueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';
import { useProducts } from '@guest-widgets/catalog/src/App/productsContext';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

interface QueryLinkProps {
  name: string;
  value: string;
  className?: string;
}

// inspired by https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
export const QueryLink = ({
  name,
  value,
  className,
  children,
}: PropsWithChildren<QueryLinkProps>) => {
  const { set, getUrl } = useQueryRouter();
  let href = getUrl(name, value);

  const { products } = useProducts();
  const onClick = (event: React.MouseEvent) => {
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      const filtredProducts = (products?.value ?? []).filter((p) => p.id == value);

      if (Array.isArray(filtredProducts) && filtredProducts.length) {
        handleTrackingEvent(TrackingEventType.SELECT_PRODUCT, { products: filtredProducts });
      }
      event.preventDefault();
      set(name, value);
      set('rwstep', 'product');
    }
  };

  return (
    <a className={className} href={href} onClick={onClick}>
      {children}
    </a>
  );
};

function isModifiedEvent(event: React.MouseEvent) {
  return event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
}
