import { datadogRum } from '@datadog/browser-rum';

import type {
  AddToCartData,
  BeginCheckoutData,
  PageViewData,
  ProductsData,
  PurchaseData,
  RemoveFromCartData,
  Tag,
  ViewCartData,
  ViewProductData,
} from './customerTrackingCreator';
import {
  AddToCartDataLayer,
  GA4Event,
  PageViewDataLayer,
  RemoveFromCartDataLayer,
  SearchProductDataLayer,
  ViewProductDataLayer,
  ViewProductListDataLayer,
} from './mappers/ga4/ga4Types';
import { GA4EventMapper } from './mappers/ga4/ga4EventsMapper';

/** It manages datadot custom conversion events, using google anaytics data structure and event names */

export class Datadog implements Tag {
  private ga4EventMapper: GA4EventMapper;

  constructor(ga4EventMapper: GA4EventMapper) {
    this.ga4EventMapper = ga4EventMapper;
  }

  private pushEvent(
    event: GA4Event,
    eventData:
      | PageViewDataLayer
      | SearchProductDataLayer
      | ViewProductListDataLayer
      | ViewProductListDataLayer
      | ViewProductDataLayer
      | AddToCartDataLayer
      | RemoveFromCartDataLayer
  ) {
    datadogRum.addAction(event, eventData);
  }
  isAvailable() {
    return !!datadogRum.getInternalContext();
  }

  viewProduct(product: ViewProductData) {
    const mappedData = this.ga4EventMapper.mapViewProduct(product);
    this.pushEvent(mappedData.event, mappedData);
    datadogRum.addAction(GA4Event.viewProduct, {
      ecommerce: product,
    });
  }
  addToCart(cart: AddToCartData) {
    const mappedData = this.ga4EventMapper.mapAddToCart(cart);
    this.pushEvent(mappedData.event, mappedData);
  }

  removeFromCart(cart: RemoveFromCartData) {
    const mappedData = this.ga4EventMapper.mapRemoveFromCart(cart);
    this.pushEvent(mappedData.event, mappedData);
  }
}
