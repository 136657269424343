import React, { PropsWithChildren } from 'react';
import { Loadable } from '@guest-widgets/shared/src/utils/loadable';

import { crossSellingContext } from '../App/crossSellingContext';

export const PreviewCrossSellingProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <crossSellingContext.Provider value={Loadable.createValid([])}>
      {children}
    </crossSellingContext.Provider>
  );
};
