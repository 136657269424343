import React from 'react';
import { Helmet } from 'react-helmet';
import type { LocationDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import { useProduct } from '../productContext';

export const GoogleStructuredData = () => {
  const { value: product } = useProduct().product;
  const eventLocation: LocationDto = product.locations[0] || {};
  const structuredData = {
    '@context': 'http://schema.org/',
    '@type': 'Event',
    name: product.title,
    startDate: '2020-01-01', // todo: required, missing in Spine
    endDate: '2024-12-31', // todo: required, missing in Spine
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',
    location: {
      '@type': 'Place',
      name: eventLocation.name,
      address: {
        '@type': 'PostalAddress',
        addressLocality: eventLocation.city,
        postalCode: eventLocation.zipCode,
      },
    },
    image: product.images.map((image) => image.url),
    description: product.information.description.body,
    ...(product.rating?.value && {
      aggregateRating: {
        '@type': 'AggregateRating',
        worstRating: 0,
        bestRating: 1,
        ratingValue: product.rating?.value,
        ratingCount: product.rating?.count,
      },
    }),
    performer: {
      '@type': 'PerformingGroup',
      name: product.operator?.name,
    },
    organizer: {
      '@type': 'Organization',
      name: product.operator?.name,
    },
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(structuredData, null, 2)}</script>
    </Helmet>
  );
};
