import React, { useEffect, useRef, useState } from 'react';
import type { ImageDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { ContainersArea, styled, useWidget } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import { useTranslator } from '@eo-locale/react';

import { ThumbnailsItem } from './ThumbnailsItem';

const pageSize = 5;
const imageAspectRatio = 0.75;
const largeScreenThumbnailWidth = 88;
const horizontalBarAspectRatio = 0.16;

interface ThumbnailsProps {
  images: ImageDto[];
  videoIds: string[];
  onChange(index: number): void;
  selectedMediaGalleryIndex: number;
}

export const Thumbnails = ({
  images,
  videoIds,
  selectedMediaGalleryIndex,
  onChange,
}: ThumbnailsProps) => {
  const items = [...images, ...videoIds];
  const { widgetArea } = useWidget();
  const [containerWidth, setContainerWidth] = useState(0);
  const containerEl = useRef<HTMLDivElement | null>(null);
  const { translate } = useTranslator();

  useEffect(() => {
    if (containerEl?.current?.offsetWidth) {
      setContainerWidth(containerEl.current.offsetWidth);
    }
  }, [containerEl?.current?.offsetWidth]);

  if (items.length < 2) return null;

  const pages = Array.from({ length: Math.ceil(items.length / pageSize) });
  const selectedPageIndex = Math.floor(selectedMediaGalleryIndex / pageSize);

  const thumbnailItemWidth = widgetArea.isLarge
    ? largeScreenThumbnailWidth
    : Math.floor(containerWidth * horizontalBarAspectRatio);
  const thumbnailItemHeight = thumbnailItemWidth * imageAspectRatio;
  const pageHeight = widgetArea.isLarge ? thumbnailItemHeight * pageSize : undefined;
  const swipeableStyles = widgetArea.isLarge
    ? { height: pageHeight }
    : { width: thumbnailItemWidth * pageSize };

  return (
    <Container ref={containerEl} className={classes.details.gallery.thumbnails.root}>
      <SwipeableViews
        className={classes.details.gallery.thumbnails.pages}
        axis={widgetArea.isLarge ? 'y' : 'x'}
        index={selectedPageIndex}
        onChangeIndex={(i) => onChange(i * pageSize)}
        enableMouseEvents
        containerStyle={swipeableStyles}
      >
        {pages.map((_, page) => (
          <Page key={page} className={classes.details.gallery.thumbnails.page} height={pageHeight}>
            {items.slice(page * pageSize, page * pageSize + pageSize).map((item, i) => (
              <ThumbnailsItem
                key={i}
                item={item}
                width={thumbnailItemWidth}
                height={thumbnailItemHeight}
                isSelected={page * pageSize + i === selectedMediaGalleryIndex}
                onClick={() => onChange(page * pageSize + i)}
              />
            ))}
          </Page>
        ))}
      </SwipeableViews>

      <Controllers>
        <div className={classes.details.gallery.thumbnails.previousButton}>
          <PreviousButton
            aria-label={translate('product-details.media-gallery.thumbnails-button-prev')}
            size="small"
            onClick={() => onChange((selectedPageIndex - 1) * pageSize)}
            disabled={selectedPageIndex === 0}
          >
            <KeyboardArrowUp />
          </PreviousButton>
        </div>
        <div className={classes.details.gallery.thumbnails.nextButton}>
          <NextButton
            aria-label={translate('product-details.media-gallery.thumbnails-button-next')}
            size="small"
            onClick={() => onChange((selectedPageIndex + 1) * pageSize)}
            disabled={selectedPageIndex === pages.length - 1}
          >
            <KeyboardArrowDown />
          </NextButton>
        </div>
      </Controllers>
    </Container>
  );
};

const Container = styled('div')(({ widgetArea }) => ({
  position: 'relative',
  display: widgetArea.isLarge ? 'block' : 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

type PageProps = {
  height?: number;
} & Partial<ContainersArea>;

const Page = styled(({ height, ...props }) => <div {...props} />)(
  ({ height, widgetArea }: PageProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: widgetArea?.isLarge ? 'column' : 'row',
    height,
  })
);

const Controllers = styled('div')(({ widgetArea }) => ({
  position: 'absolute',
  height: '100%',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: widgetArea.isLarge ? 'column' : 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: !widgetArea.isLarge ? '0 2%' : undefined,
}));

const NavigationButton = styled(IconButton)(
  ({ theme: { spacing, palette, typography }, widgetArea }) => ({
    padding: spacing(0.5),
    margin: widgetArea.isLarge ? spacing(2) : 0,
    zIndex: 1,
    backgroundColor: palette.secondary.main,
    color: palette.secondary.contrastText,
    '& svg': {
      fontSize: typography.body1.fontSize,
    },
    '&:hover': {
      backgroundColor: palette.secondary.light,
    },
    '&[class*="Mui-disabled"]': {
      backgroundColor: palette.secondary.main,
      color: palette.secondary.contrastText,
      opacity: '20%',
    },
  })
);

const PreviousButton = styled(NavigationButton)(({ widgetArea }) => ({
  transform: widgetArea.isLarge ? undefined : 'rotate(-90deg)',
}));

const NextButton = styled(NavigationButton)(({ widgetArea }) => ({
  transform: widgetArea.isLarge ? undefined : 'rotate(-90deg)',
}));
