import React from 'react';
import { Text } from '@eo-locale/react';
import { styled, useWidget } from '@guest-widgets/core';
import TrainIcon from '@mui/icons-material/Train';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import Typography from '@mui/material/Typography';
import { classes } from '@guest-widgets/shared/src/classes';
import { Feature } from '@guest-widgets/shared/src/features';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { Expandable } from '@guest-widgets/shared/src/components/Expandable/Expandable';
import { Rating } from '@guest-widgets/shared/src/components/Rating/Rating';

import { useSettings } from '../../SettingsContext/settingsContext';
import { useProduct } from '../../productContext';

import { Map } from './Map';

export const Location = () => {
  const { customerCode: currentCustomerCode } = useSettings();
  const { isEnabled } = useFeature();
  const { widgetArea } = useWidget();
  const { value: product } = useProduct().product;
  const { locations, operator } = product;
  const isPartner = operator?.code !== currentCustomerCode;

  if (locations.length === 0 || !locations[0].address || !isEnabled(Feature.Location)) return null;

  return (
    <Expandable
      className={classes.details.location.root}
      closedContent={<Text id="product-details.location.hint" />}
      title={<Text id="product-details.location.title" />}
      borderBottom
    >
      {locations.map((location) => (
        <>
          <Description className={classes.details.location.description}>
            {location.description}
          </Description>
          {!widgetArea.isLarge && <Map location={location} />}
          <Content className={classes.details.location.details.root}>
            <div className={classes.details.location.details.address}>
              <Typography component="h4" variant="h4">
                <Text id="product-details.location.address" />
              </Typography>
              <Typography variant="body2">{location.address}</Typography>
            </div>
            {operator && isPartner && (
              <div className={classes.details.location.details.operator}>
                <Typography component="h4" variant="h4">
                  <Text id="product-details.location.operator" />
                </Typography>
                <Typography variant="body2">
                  {operator.name}
                  <br />
                  {operator.address && `${operator.address.zipCode} – ${operator.address?.city}`}
                  {operator.rating?.count ? (
                    <Rating value={operator.rating.value * 5} noLabel />
                  ) : null}
                </Typography>
              </div>
            )}
            <div className={classes.details.location.details.name}>
              <Typography component="h4" variant="h4">
                <Text id="product-details.location.name" />
              </Typography>
              <Typography variant="body2">{location.name}</Typography>
            </div>
          </Content>
          <Content className={classes.details.location.additional.root}>
            {location.parkingOptions && (
              <ContentWithIcon className={classes.details.location.additional.parking}>
                <Parking>
                  <LocalParkingIcon />
                </Parking>
                <div>
                  <Typography component="h4" variant="h4">
                    <Text id="product-details.location.parking" />
                  </Typography>
                  <Typography variant="body2">{location.parkingOptions}</Typography>
                </div>
              </ContentWithIcon>
            )}
            {location.publicTransport && (
              <ContentWithIcon className={classes.details.location.additional.publicTransport}>
                <PublicTransportation>
                  <TrainIcon />
                </PublicTransportation>
                <div>
                  <Typography component="h4" variant="h4">
                    <Text id="product-details.location.public-transportation" />
                  </Typography>
                  <Typography variant="body2">{location.publicTransport}</Typography>
                </div>
              </ContentWithIcon>
            )}
          </Content>
          {widgetArea.isLarge && <Map location={location} />}
        </>
      ))}
    </Expandable>
  );
};

const IconProps = styled('div')(({ theme: { spacing } }) => ({
  borderRadius: spacing(1),
  width: spacing(9),
  textAlign: 'center',
  verticalAlign: 'middle',
  marginRight: spacing(2),
  padding: `${spacing(1.5)} 0`,

  '& svg': {
    margin: 'auto',
    display: 'block',
  },
}));

const Parking = styled(IconProps)(({ theme }) => ({
  backgroundColor: '#444F60',
  color: theme.palette.common.white,
}));

const PublicTransportation = styled(IconProps)(({ theme }) => ({
  backgroundColor: '#64B473',
  color: theme.palette.common.white,
}));

const ContentWithIcon = styled('div')(({ theme, widgetArea }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: widgetArea.isLarge ? undefined : theme.spacing(4),
  borderTop: widgetArea.isLarge ? undefined : theme.border,
}));

const Description = styled('div')(({ theme, widgetArea }) => ({
  marginBottom: theme.spacing(widgetArea.isLarge ? 8 : 4),
}));

const Content = styled('div')(({ theme, widgetArea }) => ({
  marginBottom: widgetArea.isLarge ? theme.spacing(7) : undefined,
  display: widgetArea.isLarge ? 'flex' : 'block',
  justifyContent: 'space-between',
  '& > *': {
    marginBottom: widgetArea.isLarge ? 0 : theme.spacing(4),
    width: '100%',
    flexShrink: 1,
  },
}));
