import React from 'react';
import { Text } from '@eo-locale/react';
import { Typography } from '@mui/material';
import { styled, useWidget } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import { Feature } from '@guest-widgets/shared/src/features';
import { useFeature } from '@guest-widgets/shared/src/contexts/featureContext/featureContext';
import { Expandable } from '@guest-widgets/shared/src/components/Expandable/Expandable';

import { useProduct } from '../productContext';

export const Description = () => {
  const { isEnabled } = useFeature();
  const { value: product } = useProduct().product;
  const { detailedDescription } = product.information;
  const { widgetArea } = useWidget();

  return detailedDescription && isEnabled(Feature.LongDescription) ? (
    <Expandable
      className={classes.details.description.root}
      isInitiallyOpen={widgetArea.isLarge}
      title={<Text id="product-details.description.title" />}
      closedContent={<Text id="product-details.description.hint" />}
      borderBottom
    >
      <StyledDiv
        variant="body1"
        className={classes.details.description.body}
        dangerouslySetInnerHTML={{ __html: detailedDescription.body }}
      />
    </Expandable>
  ) : null;
};

const StyledDiv = styled(Typography)({
  '& p': {
    marginBlockStart: 'unset',
    marginBlockEnd: 'unset',
  },
});
