import { SupportedCurrencies } from '@guest-widgets/booking/src/App/contexts/settingsContext/settings';

export enum ViewEvent {
  view = 'page_view',
  search = 'search',
  product = 'view_item',
  virtualPage = 'virtualPageview',
  viewItemList = 'view_item_list',
  viewCart = 'view_cart',
}

export enum GA4Event {
  viewProduct = 'view_item',
  search = 'search',
  addToCart = 'add_to_cart',
  removeFromCart = 'remove_from_cart',
  checkout = 'begin_checkout',
  purchase = 'purchase',
  viewProductList = 'view_item_list',
  selectProduct = 'select_item',
  viewCart = 'view_cart',
}
export interface TrackingId {
  tracking_adw_id: string;
  tracking_adw_label: string;
  tracking_fb_id: string;
  tracking_ga_id: string;
}

export interface PurchaseEcommerceGtm {
  transaction_id: string;
  value: number;
  tax: number;
  shipping: number;
  currency: string;
  coupon?: string;
  discount?: number;
  items: GA4Items[];
}

export enum FBEvent {
  view = 'ViewContent',
  select = 'Search',
  add = 'AddToCart',
  checkout = 'InitiateCheckout',
  purchase = 'Purchase',
}

export enum CustomEvent {
  add = 'actionAddToCart',
  remove = 'actionRemoveFromCart',
  checkout = 'actionCheckout',
  purchase = 'actionPurchase',
}

export enum ADWEvent {
  conversion = 'conversion',
}

export interface GA4Items {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_variant: string;
  quantity: number;
  price: number;
  tax?: number;
  price_incl_tax?: number;
}

export interface GA4ViewCartData {
  value: number;
  currency: SupportedCurrencies;
  items: GA4Items[];
}

export interface FBContents {
  id: string;
  name: string;
  quantity: number;
  item_price: number;
}

export interface EcommerceItem {
  value: number;
  currency: string;
  items: GA4Items[];
}

export interface CheckoutEcommerceG4 extends EcommerceItem {
  coupon?: string;
}

export interface PageViewDataLayer {
  event: ViewEvent.virtualPage | ViewEvent.view;
  page_title: string;
  page_location: string;
  page_path?: string;
  page_referrer?: string;
}

export interface SearchProductDataLayer {
  event: ViewEvent.search;
  ecommerce: {
    search_term: string;
  };
}

export interface ViewProductListDataLayer {
  event: GA4Event;
  ecommerce: {
    items: GA4Items[];
  };
}

export interface ViewProductDataLayer {
  event: GA4Event.viewProduct;
  ecommerce: EcommerceItem;
}

export interface ViewCartDataLayer {
  event: GA4Event.viewCart;
  ecommerce: EcommerceItem;
}

export interface AddToCartDataLayer {
  event: GA4Event;
  ecommerce: EcommerceItem;
}

export interface RemoveFromCartDataLayer {
  event: GA4Event;
  ecommerce: EcommerceItem;
}

export interface PurchaseDataLayer {
  event: GA4Event.purchase;
  ecommerce: PurchaseEcommerceGtm;
}

export interface BeginCheckoutDataLayer {
  event: GA4Event.checkout;
  ecommerce: CheckoutEcommerceG4;
}

export interface CartProps {
  value: number;
  value_incl_tax: number;
  currency: string;
  ga4_items: GA4Items[];
  fb_contents?: FBContents[];
}
export interface ViewItemProps {
  id: string;
  name: string;
  brand: string;
  category: string;
  price: number;
}

export interface ItemArgs {
  product: MappedProduct;
  category: string;
  qty: number;
  itemPrice?: number;
  variant?: string;
  price_incl_tax?: number;
  tax?: number;
}
export interface ItemArgs {
  product: MappedProduct;
  category: string;
  qty: number;
  itemPrice?: number;
  variant?: string;
  price_incl_tax?: number;
  tax?: number;
}

export interface MappedProduct {
  productId: string;
  name: string;
  brand: string;
}
