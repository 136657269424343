import React from 'react';
import { Text } from '@eo-locale/react';
import { Button, IconButton, Typography } from '@mui/material';
import Chevron from '@mui/icons-material/ChevronRight';
import { useWidget, styled } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';

interface ReturnProps {
  onBack?(): void;
}

export const Return = ({ onBack }: ReturnProps) => {
  const { widgetArea } = useWidget();

  return widgetArea.isSmall ? (
    <ReturnMobile className={classes.details.backLink.mobile}>
      <IconButton data-cy="drawer-close-button" onClick={onBack} size="large">
        <BackIcon />
      </IconButton>
      <Typography component="h2" variant="h4">
        <Text id="product-details.catalog" />
      </Typography>
    </ReturnMobile>
  ) : (
    <ReturnDesktop color="secondary" className={classes.details.backLink.desktop} onClick={onBack}>
      <Text id="product-details.back" />
    </ReturnDesktop>
  );
};

const ReturnDesktop = styled(Button)(({ theme }) => ({
  display: undefined,
  textTransform: 'none',
  textDecoration: 'underline',
  marginBottom: theme.spacing(2),
  minWidth: 'auto',
  padding: 0,
}));

const ReturnMobile = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  padding: spacing(1, 4, 1, 0),
  alignItems: 'center',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  margin: spacing(-4, -4, 4),
}));

const BackIcon = styled(Chevron)({
  transform: 'rotate(180deg)',
});
