import React, { createContext, useContext, ReactNode, PropsWithChildren } from 'react';
import { objectsMerge } from '@guest-widgets/shared/src/utils/objectsMerge';
import { useBackendSettings } from '@guest-widgets/shared/src/hooks/useBackendSettings';
import { SharedWidgetSettings } from '@guest-widgets/shared/src/types/featureSetting';
import { WidgetConfigurationDto } from '@checkfront/guest-widgets-editor-api-api-client-javascript-axios';
import { LanguageLocaleCode, formatLocaleForI18n } from '@guest-widgets/shared/src/i18n/i18n';

import { CatalogSkeleton } from './CatalogSkeleton';
import { Filters } from './filters';

export interface SettingsContext extends SharedWidgetSettings {
  categoryFilter?: Filters['categories'];
  locationFilter?: Filters['location'];
}

const settingsContext = createContext({} as SettingsContext);

type WidgetSettings = Partial<SettingsContext>;

export interface SettingsContextProps extends WidgetSettings {
  widgetId?: string;
}

export const SettingsProvider = ({
  children,
  widgetId,
  ...settingsFromProps
}: PropsWithChildren<SettingsContextProps>) => {
  const settingsFromBackend = useBackendSettings(widgetId, mapToWidgetSettings);

  if (!widgetId) {
    return render(children, settingsFromProps);
  }

  if (settingsFromBackend.isFailed) {
    return renderError(settingsFromBackend.error);
  }

  if (!settingsFromBackend.isValid) {
    // While backend settings are loading we might have already necessary settings from widget props
    return render(children, settingsFromProps, true);
  }

  // settings merged by priority order [higher to lower]
  const mergedSettings = objectsMerge(settingsFromProps, settingsFromBackend.value);

  return render(children, mergedSettings);
};

export const SettingsConsumer = settingsContext.Consumer;
export const useSettings = () => useContext(settingsContext);

const mapToWidgetSettings = (settingsAndConfigs: WidgetConfigurationDto): WidgetSettings => {
  const { configuration, settings } = settingsAndConfigs;
  const { categoryPrefilter, locationPrefilter, ...rest } = settings;
  return {
    ...rest,
    locale: (rest.locale as unknown) as LanguageLocaleCode,
    categoryFilter: categoryPrefilter?.map(({ id }) => id),
    locationFilter: locationPrefilter,
    configuration,
  };
};

const render = (children: ReactNode, settings: WidgetSettings, isLoading = false) => {
  const { customerCode, locale, ...rest } = settings;

  if (!customerCode || !locale) {
    if (isLoading) return <CatalogSkeleton />;
    return renderError('Required properties are missing in the widget settings', settings);
  }

  return (
    <settingsContext.Provider
      value={{
        ...rest,
        customerCode,
        locale: formatLocaleForI18n(locale) as LanguageLocaleCode,
      }}
    >
      {children}
    </settingsContext.Provider>
  );
};

const renderError = (...logMessage: unknown[]) => {
  if (logMessage.length) console.error(...logMessage);
  // todo: show error screen
  return null;
};
