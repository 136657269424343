import { CartItem } from '@guest-widgets/booking/src/App/contexts/apiContext/cart/cart';

export const calculateItemDetails = (item: CartItem, total: number) => {
  const tax = item.price.tax?.amount || 0;
  const inclusiveTaxTotal = item.price.inclusiveTaxTotal?.amount || 0;
  const value = total - inclusiveTaxTotal;
  const value_incl_tax = total + tax;
  const totalTaxes = inclusiveTaxTotal + tax;
  const totalQuantity = Object.values(item.guestTypes).reduce((sum, qty) => sum + qty, 0);

  return {
    value,
    value_incl_tax,
    totalTaxes,
    tax,
    inclusiveTaxTotal,
    totalQuantity,
  };
};
