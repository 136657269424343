import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import type {
  ImageDto,
  VideoDto,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { ContainersArea, styled } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import { useTranslator } from '@eo-locale/react';
import { Theme } from '@mui/material';
import NoImageIcon from '@guest-widgets/shared/src/components/Icons/NoImageIcon';

import { Video } from './Video/Video';
import { getVideoId } from './youtubeUtils';
import { Thumbnails } from './Thumbnails/Thumbnails';

interface MediaGalleryProps {
  images: ImageDto[];
  videos: VideoDto[];
}

export const MediaGallery = ({ images, videos }: MediaGalleryProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerEl = useRef<HTMLDivElement | null>(null);
  const { translate } = useTranslator();

  const maxSteps = images.length + videos.length;
  const videoIds = videos
    .map(({ url }) => getVideoId(url))
    .filter((videoId) => !!videoId) as string[];

  const showMediaNav = images?.length + videoIds?.length > 1;

  const showNext = () => {
    setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
  };

  const showPrevious = () => {
    setActiveIndex((prevActiveIndex) => prevActiveIndex - 1);
  };

  const changeIndex = (newActiveIndex: number) => {
    setActiveIndex(newActiveIndex);
  };

  useEffect(() => {
    if (containerEl?.current?.offsetWidth) {
      setContainerWidth(containerEl.current.offsetWidth);
    }
  }, [containerEl?.current?.offsetWidth]);

  return (
    <Container qtdItems={[...videos, ...images].length}>
      <Thumbnails
        selectedMediaGalleryIndex={activeIndex}
        videoIds={videoIds}
        images={images}
        onChange={(i) => changeIndex(i)}
      />
      <Screen ref={containerEl} className={classes.details.gallery.screen.root}>
        {!images.length && !videoIds.length ? (
          <ImagePlaceholder
            role="img"
            containerWidth={containerWidth}
            className={classes.details.gallery.screen.imageSlide}
          >
            <NoImageIcon sx={{ width: '50%', height: '50%' }} />
          </ImagePlaceholder>
        ) : (
          <StyledSwipeableViews
            axis="x"
            index={activeIndex}
            onChangeIndex={changeIndex}
            enableMouseEvents
          >
            {images.map((image, index) => (
              <Slide
                key={`image-${index}`}
                containerWidth={containerWidth}
                className={classes.details.gallery.screen.imageSlide}
              >
                {Math.abs(activeIndex - index) <= 2 ? (
                  <Image src={image.url} alt={image.alt} />
                ) : null}
              </Slide>
            ))}
            {videoIds.map((videoId, index) => (
              <Slide
                key={`video-${index}`}
                containerWidth={containerWidth}
                className={classes.details.gallery.screen.videoSlide}
              >
                {Math.abs(activeIndex - index - images.length) <= 2 ? (
                  <Video videoId={videoId} isActive={activeIndex === images.length + index} />
                ) : null}
              </Slide>
            ))}
          </StyledSwipeableViews>
        )}
        {showMediaNav && (
          <>
            <PreviousButton
              aria-label={translate('product-details.media-gallery.image-button-prev')}
              className={classes.details.gallery.screen.previousButton}
              onClick={showPrevious}
              disabled={activeIndex === 0}
            >
              <KeyboardArrowLeft />
            </PreviousButton>
            <NextButton
              aria-label={translate('product-details.media-gallery.image-button-next')}
              className={classes.details.gallery.screen.nextButton}
              onClick={showNext}
              disabled={activeIndex === maxSteps - 1}
            >
              <KeyboardArrowRight />
            </NextButton>
          </>
        )}
      </Screen>
    </Container>
  );
};

type ContainerProps = {
  qtdItems: number;
  theme: Theme;
  widgetArea?: ContainersArea['widgetArea'];
} & HTMLAttributes<HTMLDivElement>;

const Container = styled(({ qtdItems, ...rest }) => <div {...rest} />)(
  ({ qtdItems, theme, widgetArea }: ContainerProps) =>
    !widgetArea?.isLarge || qtdItems < 2
      ? {
          display: 'flex',
          flexDirection: 'column-reverse',
          '& > *:last-child': {
            marginBottom: theme.spacing(3),
          },
        }
      : {
          display: 'grid',
          gridTemplateColumns: '20% 80%',
          alignItems: 'center',
        }
);

const Screen = styled('div')({
  position: 'relative',
  maxWidth: 600,
  flexGrow: 2,
  overflow: 'hidden',
});

const StyledSwipeableViews = styled(SwipeableViews)(({ theme }) => ({
  alignItems: 'stretch',
  // This prevents border-radius from being applied when image ocupies the whole screen width
  borderRadius: `max(0px, min(${theme.shape.borderRadius}px, calc((100vw - 4px - 100%) * 9999)))`,
  '& > .react-swipeable-view-container > div': {
    overflow: 'hidden !important', //Override inline CSS added by react-swipeable-views
  },
}));

type SlideProps = {
  containerWidth: number;
} & React.HTMLAttributes<HTMLDivElement>;

const Slide = styled(({ containerWidth, ...rest }: SlideProps) => <div {...rest} />)(
  ({ containerWidth }: SlideProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: containerWidth || '100%',
    height: containerWidth ? (containerWidth * 2) / 3 : undefined,
  })
);

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const ImagePlaceholder = styled(({ containerWidth, ...rest }: SlideProps) => <div {...rest} />)(
  ({ containerWidth }: SlideProps) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: containerWidth || '100%',
    height: containerWidth ? (containerWidth * 2) / 3 : undefined,
    background: '#F1F5F9',
    borderRadius: 6,
  })
);

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
  '&[class*="Mui-disabled"]': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    opacity: '20%',
  },
}));

const PreviousButton = styled(NavigationButton)(({ theme }) => ({
  left: theme.spacing(4),
}));

const NextButton = styled(NavigationButton)(({ theme }) => ({
  right: theme.spacing(4),
}));
