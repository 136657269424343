import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { CrossSellingProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { Loadable, useLoadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { productApi } from '@guest-widgets/shared/src/apis/guestExperience';

import { useSettings } from './SettingsContext/settingsContext';

export const crossSellingContext = createContext({} as Loadable<CrossSellingProductDto[]>);

export const CrossSellingProvider = ({ children }: PropsWithChildren<{}>) => {
  const { customerCode, productId, locale } = useSettings();
  const [products, loadProducts] = useLoadable(productApi.getCrossSelling);

  useEffect(() => {
    loadProducts(productId, customerCode, locale);
  }, [productId, customerCode, locale]);

  return <crossSellingContext.Provider value={products}>{children}</crossSellingContext.Provider>;
};

export const useCrossSelling = () => useContext(crossSellingContext);
