export enum ProductItemFeature {
  ProductLocation = 'product-location',
  ProductDuration = 'product-duration',
  ProductRating = 'product-rating',
  ProductPrice = 'product-price',
  ProductButton = 'product-button',
  ProductShowDescription = 'show-description',
}

export const defaultProductItemFeatures = {
  [ProductItemFeature.ProductLocation]: true,
  [ProductItemFeature.ProductDuration]: true,
  [ProductItemFeature.ProductRating]: true,
  [ProductItemFeature.ProductPrice]: true,
  [ProductItemFeature.ProductButton]: true,
  [ProductItemFeature.ProductShowDescription]: true,
};
