import React from 'react';
import { parseJson, splitProp, MuiProvider, getThemeType } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';

import { PreviewApp, PreviewAppProps } from './PreviewApp/PreviewApp';

export interface ProductPreviewWidgetWebComponentProps
  extends Omit<
    PreviewAppProps,
    'productId' | 'disableFeatures' | 'enableFeatures' | 'configuration'
  > {
  disableFeatures?: string;
  enableFeatures?: string;
  configuration?: string;
  dataDisableFont?: string;
}

export const ProductPreviewWidgetWebComponent = ({
  disableFeatures,
  enableFeatures,
  configuration,
  ...rest
}: ProductPreviewWidgetWebComponentProps) => {
  const listEnableFeatures = splitProp(enableFeatures);
  const disableDownloadFont = rest.dataDisableFont === 'true';

  return (
    <>
      <MuiProvider
        classNamePrefix={classes.catalog.prefix}
        themeType={getThemeType(listEnableFeatures)}
      >
        <PreviewApp
          {...rest}
          disableDownloadFont={disableDownloadFont}
          disableFeatures={splitProp(disableFeatures)}
          enableFeatures={listEnableFeatures}
          configuration={parseJson(configuration)}
        />
      </MuiProvider>
    </>
  );
};
