import React, { CSSProperties, ReactNode } from 'react';
import { WithTheme } from '@mui/styles';
import MaterialDrawer, { DrawerProps as MaterialDrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chevron from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import { styled, useWidget } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';

import { useDisableScroll } from './useDisableScroll';

export type DrawerProps = {
  isOpen: boolean;
  title: string | ReactNode;
  children?: ReactNode;
  disableScroll?: boolean;
  onClose(): void;
} & Omit<MaterialDrawerProps, 'title'>;

export const Drawer = ({
  isOpen,
  title,
  children,
  disableScroll,
  onClose,
  ...props
}: DrawerProps) => {
  const { viewportArea } = useWidget();
  const StyledDrawer = viewportArea.isSmall ? StyledDrawerBottom : StyledDrawerRight;

  useDisableScroll(isOpen);

  return (
    <StyledDrawer
      className={classes.details.drawer.root}
      open={isOpen}
      onClose={onClose}
      anchor={viewportArea.isSmall ? 'bottom' : 'right'}
      style={{ '--width': viewportArea.isLarge ? '600px' : '50%' } as CSSProperties}
      {...props}
    >
      <DrawerHeader className={classes.details.drawer.header}>
        <StyledIconButton data-cy="drawer-close-button" onClick={onClose}>
          <BackIcon />
        </StyledIconButton>
        <Box m={3}>
          <Typography component="h2" variant="h4">
            {title}
          </Typography>
        </Box>
      </DrawerHeader>
      <Content className={classes.details.drawer.content} disableScroll={disableScroll}>
        {children}
      </Content>
    </StyledDrawer>
  );
};

const paperCornerRadius = '12px';

const StyledIconButton = styled(IconButton)(({ widgetArea }) =>
  widgetArea.isSmall
    ? {
        position: 'absolute',
        top: 0,
        left: 'calc(50% - 12px)',
        padding: 0,
        transform: 'rotate(-90deg)',
        color: 'none !important',
      }
    : {}
);

const StyledDrawerBottom = styled(MaterialDrawer)(({ theme }) => ({
  '& [class*="MuiDrawer-paper"]': {
    height: `calc(100% - ${theme.spacing(16)})`,
    borderTopLeftRadius: paperCornerRadius,
    borderTopRightRadius: paperCornerRadius,
  },
}));

const StyledDrawerRight = styled(MaterialDrawer)({
  '& [class*="MuiDrawer-paper"]': {
    width: 'var(--width)',
    borderTopLeftRadius: paperCornerRadius,
    borderBottomLeftRadius: paperCornerRadius,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 4, 1, 0),
  borderBottom: theme.border,
}));

const BackIcon = styled(Chevron)(({ theme }) => ({
  transform: 'rotate(180deg)',
  color: theme.palette.text.primary,
}));

const Content = styled('div')(
  ({ disableScroll, theme }: Pick<DrawerProps, 'disableScroll'> & WithTheme) => ({
    overflow: disableScroll ? 'hidden' : 'scroll',
    padding: disableScroll ? undefined : theme.spacing(0, 4, 4),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  })
);
