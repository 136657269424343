import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React from 'react';
import { classes } from '@guest-widgets/shared/src/classes';
import { styled } from '@guest-widgets/core';

import { useProduct } from '../../productContext';

export const Location = () => {
  const {
    product: { isValid, value },
  } = useProduct();

  return isValid && value.locations[0]?.city ? (
    <StyledChip
      className={classes.details.labels.location}
      icon={<LocationOnIcon fontSize="small" />}
      label={value.locations[0].city}
    />
  ) : null;
};

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
