import React from 'react';
import type { DurationDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { Text, useTranslator } from '@eo-locale/react';
import { mapDurationToLocalString } from '@guest-widgets/shared/src/mappers/durationMapper';

export interface DurationFromProps {
  duration: DurationDto[];
}

export const DurationFrom = ({ duration }: DurationFromProps) => {
  const { translate } = useTranslator();

  return (
    <>
      {duration.length > 1 ? (
        <Text
          id="product-list-item.duration.from"
          unit={translate(
            'product-list-item.duration.' + mapDurationToLocalString(duration[0].unit),
            {
              count: duration[0].value,
            }
          )}
        />
      ) : (
        <Text
          id={'product-list-item.duration.' + mapDurationToLocalString(duration[0].unit)}
          count={duration[0].value}
        />
      )}
    </>
  );
};
