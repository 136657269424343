import React, { ReactNode } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled, useWidget } from '@guest-widgets/core';

import { StyledHead, TitleContainer, Tags } from '../ProductDetails/ProductHead/ProductHead';

export const ProductInformationSkeleton = () => {
  const { widgetArea } = useWidget();

  const MediaGallerySkeleton = () => (
    <GallerySkeleton>
      {widgetArea.isLarge ? (
        <Box display="flex" width="100%" height={400}>
          <LargeGallerySlider variant="rectangular" width="20%" height="auto" />
          <Skeleton variant="rectangular" width="80%" height="100%" />
        </Box>
      ) : (
        <Box width="100%" height={300}>
          <Skeleton variant="rectangular" width="100%" height="90%" />
          <Box justifyContent="space-evenly" m={2} display="flex" height="10%">
            <Skeleton variant="circular" width={30} height="auto" />
            <Skeleton variant="rectangular" width={200} height="auto" />
            <Skeleton variant="circular" width={30} height="auto" />
          </Box>
        </Box>
      )}
    </GallerySkeleton>
  );

  const getRandomInt = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getTextSkeletons = (count: number): ReactNode[] => {
    let skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(<Skeleton variant="text" width={`${getRandomInt(2, 9) * 10}%`} height={30} />);
    }
    return skeletons;
  };

  const getDescriptionSkeletons = (count: number): ReactNode[] => {
    let skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <Box>
          <Skeleton variant="rectangular" width={300} height={30} />
          {getTextSkeletons(getRandomInt(2, 4))}
          <StyledDivider />
        </Box>
      );
    }
    return skeletons;
  };

  const components: { [key: string]: ReactNode } = {
    title: (
      <TitleContainer>
        <Skeleton variant="text" width="100%" height={50} />
        {widgetArea.isSmall && (
          <>
            <Skeleton variant="text" width="90%" height={30} />
            <Skeleton variant="text" width="50%" height={30} />
          </>
        )}
        {!widgetArea.isSmall && (
          <Labels>
            <LabelSkeleton variant="rectangular" width={100} height={30} />
            <LabelSkeleton variant="rectangular" width={100} height={30} />
            <LabelSkeleton variant="rectangular" width={100} height={30} />
          </Labels>
        )}
      </TitleContainer>
    ),
    tags: (
      <Tags>
        {!widgetArea.isSmall && (
          <>
            <Skeleton variant="rectangular" width={200} height={25} />
            <Skeleton variant="rectangular" width={200} height={25} />
          </>
        )}
      </Tags>
    ),
    mediaGallery: <MediaGallerySkeleton />,
    description: getDescriptionSkeletons(4),
  };

  const order = widgetArea.isSmall
    ? ['mediaGallery', 'title', 'tags', 'description']
    : ['title', 'tags', 'mediaGallery', 'description'];

  return <StyledHead>{order.map((key) => components[key])}</StyledHead>;
};

const Labels = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const LabelSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: '10px',
  marginRight: theme.spacing(2),
}));

const LargeGallerySlider = styled(Skeleton)(({ theme }) => ({
  margin: theme.spacing(5, 2, 5, 0),
}));

const GallerySkeleton = styled('div')(({ theme, widgetArea }) => ({
  ...widgetArea.basedOnSize(
    {
      margin: `-${theme.spacing(8)} -${theme.spacing(8)} 0`,
      width: 'auto',
    },
    {
      width: '100%',
    }
  ),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(6),
}));
