import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Loadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { productApi } from '@guest-widgets/shared/src/apis/guestExperience';
import type { ReviewDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import { useSettings } from './SettingsContext/settingsContext';

export interface ReviewsContext {
  reviews: Loadable<ReviewDto[]>;
  totalCount: number | undefined;
  load(): void;
}

export const pageSize = 10;

export const reviewsContext = createContext({} as ReviewsContext);

export const ReviewsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { productId, customerCode, locale } = useSettings();
  const [reviews, setReviews] = useState(Loadable.createEmpty<ReviewDto[]>());
  // we need to hold a local count because the backend returns 0 if offset > totalItemCount
  const countRef = useRef<undefined | number>(undefined);
  const pageRef = useRef(0);

  const load = async () => {
    setReviews(({ value }) => Loadable.createLoading(pageRef.current === 0 ? undefined : value));
    try {
      const offset = pageRef.current * pageSize;
      const { items, totalItemCount } = await productApi.getReviews(
        productId,
        customerCode,
        locale,
        { offset, limit: pageSize }
      );
      if (!countRef.current && totalItemCount) {
        countRef.current = totalItemCount;
      }
      setReviews(({ value }) => Loadable.createValid((value || []).concat(items)));
      pageRef.current++;
    } catch (e) {
      setReviews(Loadable.createFailed(e));
    }
  };

  useEffect(() => {
    countRef.current = undefined;
    pageRef.current = 0;
    load();
  }, [productId, customerCode, locale]);

  const value: ReviewsContext = {
    reviews,
    totalCount: countRef.current,
    load,
  };

  return <reviewsContext.Provider value={value}>{children}</reviewsContext.Provider>;
};

export const useReviews = () => useContext(reviewsContext);
