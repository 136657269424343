import React, { PropsWithChildren } from 'react';
import { Loadable } from '@guest-widgets/shared/src/utils/loadable';

import { ReviewsContext, reviewsContext } from '../App/reviewsContext';

export const PreviewReviewsProvider = ({ children }: PropsWithChildren<{}>) => {
  let value: ReviewsContext = {
    reviews: Loadable.createValid([]),
    totalCount: 0,
    load() {},
  };
  return <reviewsContext.Provider value={value}>{children}</reviewsContext.Provider>;
};
