import React from 'react';
import Chip from '@mui/material/Chip';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import { classes } from '@guest-widgets/shared/src/classes';
import { DurationFrom } from '@guest-widgets/shared/src/components/DurationFrom';
import { styled } from '@guest-widgets/core';

import { useProduct } from '../../productContext';

export const Duration = () => {
  const {
    product: { isValid, value },
  } = useProduct();

  return isValid && value.duration && value.duration.length > 0 ? (
    <StyledChip
      className={classes.details.labels.duration}
      icon={<QueryBuilderRoundedIcon />}
      label={<DurationFrom duration={value.duration} />}
    />
  ) : null;
};

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.info.main,
  '& svg': {
    color: theme.palette.text.primary,
  },
}));
