import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { classes } from '@guest-widgets/shared/src/classes';

import { getEmbedUrl, getPreviewUrl } from '../youtubeUtils';

import { PlayButton } from './PlayButton';

interface VideoProps {
  videoId: string;
  isActive: boolean;
}

export const Video = ({ videoId, isActive }: VideoProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [videoIsDisplayed, setVideoIsDisplayed] = useState(false);

  useEffect(() => setVideoIsDisplayed((wasDisplayed) => wasDisplayed && isActive), [isActive]);

  const startVideo = () => setVideoIsDisplayed(true);

  if (!videoId) return null;

  return (
    <VideoContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={startVideo}
    >
      {videoIsDisplayed && (
        <iframe
          className={classes.details.gallery.screen.video.frame}
          src={`${getEmbedUrl(videoId)}`}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay; fullscreen"
        />
      )}
      {!videoIsDisplayed && (
        <Preview className={classes.details.gallery.screen.video.preview}>
          <PreviewImage url={getPreviewUrl(videoId)} />
          <PlayButton hovered={isHovered} onClick={startVideo} />
        </Preview>
      )}
    </VideoContainer>
  );
};

const VideoContainer = styled('div')({
  position: 'relative',
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

const Preview = styled('div')({
  width: '100%',
});

type PreviewImageProps = {
  url: string;
};

const PreviewImage = styled(({ url, ...props }: PreviewImageProps) => <div {...props} />)(
  ({ url }) => ({
    width: '100%',
    paddingBottom: '66.66%',
    backgroundImage: `url(${url})`,
    backgroundPosition: 'center',
  })
);
