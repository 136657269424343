import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { ProductListItemSkeleton } from '@guest-widgets/shared/src/components/ProductListItem/ProductListItemSkeleton';

import { ItemsWrapper, ItemWrapper, Items } from '../ProductDetails/CrossSelling';

export const CrossSellingSkeleton = () => {
  return (
    <div>
      <Skeleton variant="text" width={200} height={40} />
      <ItemsWrapper>
        <Items>
          <ItemWrapper>
            <ProductListItemSkeleton />
          </ItemWrapper>
          <ItemWrapper>
            <ProductListItemSkeleton />
          </ItemWrapper>
          <ItemWrapper>
            <ProductListItemSkeleton />
          </ItemWrapper>
        </Items>
      </ItemsWrapper>
    </div>
  );
};
