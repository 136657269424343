import { DetailProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios/dist/api';
import { WidgetWebComponent } from '@guest-widgets/core';

type ProductPreviewWidgetWebComponent = WidgetWebComponent & {
  customProps: {
    product?: DetailProductDto;
  };
};

export function customize(constructor: CustomElementConstructor) {
  Object.defineProperties(constructor.prototype, {
    customProps: { value: {} },
    product: {
      set: function (this: ProductPreviewWidgetWebComponent, value: DetailProductDto) {
        this.customProps.product = value;
        // Important part is here: you re-render your component every time the property is set.
        this.render();
      },
      get: function (): DetailProductDto {
        return this.customProps.product || [];
      },
    },
  });
}
