import React, { PropsWithChildren } from 'react';
import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';

import { SettingsContext, settingsContext } from '../App/SettingsContext/settingsContext';

type WidgetSettings = Partial<
  Omit<SettingsContext, 'productId' | 'customerCode' | 'locale' | 'subId'>
>;

export interface PreviewSettingsProviderProps extends WidgetSettings {
  locale?: string;
}

export const PreviewSettingsProvider = ({
  locale,
  children,
}: PropsWithChildren<PreviewSettingsProviderProps>) => {
  const value: SettingsContext = {
    productId: 'preview',
    customerCode: 'preview',
    locale: (locale as LanguageLocaleCode) || 'de_DE',
  };

  return <settingsContext.Provider value={value}>{children}</settingsContext.Provider>;
};

export const PreviewSettingsConsumer = settingsContext.Consumer;
