import { PageViewData } from '../../customerTrackingCreator';

import { PageViewDataPaq } from './matomoTypes';

export class MatomoEventMapper {
  mapPageView = ({ title, virtualPageViewData }: PageViewData): PageViewDataPaq => {
    if (virtualPageViewData) {
      const { path } = virtualPageViewData;
      const location = window.location.origin + path;
      return {
        page_title: virtualPageViewData.title,
        page_location: location,
        page_referrer: document.referrer,
      };
    }
    return {
      page_title: title!,
      page_location: location.origin,
      page_referrer: document.referrer,
    };
  };
}
