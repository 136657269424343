import { useState, useEffect } from 'react';

export const useGoogleMaps = () => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => init(), []);

  const init = () => {
    const intervalId = setInterval(() => {
      if (!window.google?.maps) {
        return;
      }

      clearInterval(intervalId);
      setIsReady(true);
    }, 10);

    return () => {
      clearInterval(intervalId);
    };
  };

  return isReady ? window.google.maps : undefined;
};
