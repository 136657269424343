import React from 'react';
import { styled } from '@mui/material/styles';
import { WithTheme } from '@mui/styles';
import { classes } from '@guest-widgets/shared/src/classes';

export interface PlayButtonProps {
  hovered: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const PlayButton = ({ hovered, onClick }: PlayButtonProps) => (
  <StyledButton className={classes.details.gallery.screen.video.playButton} onClick={onClick}>
    <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
      <StyledPath
        hovered={hovered}
        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
        fill="#f00"
      />
      <path d="M 45,24 27,14 27,34" fill="#fff" />
    </svg>
  </StyledButton>
);

const StyledButton = styled('button')({
  cursor: 'pointer',
  outline: 0,
  border: 0,
  backgroundColor: 'transparent',
  padding: 0,
  position: 'absolute',
  left: '50%',
  top: '50%',
  width: 68,
  height: 48,
  transition: 'opacity .25s cubic-bezier(0.0,0.0,0.2,1)',
  transform: 'translate(-50%, -50%)',
});

const StyledPath = styled('path')(({ hovered }: WithTheme & { hovered: boolean }) => ({
  transition: 'fill .1s cubic-bezier(0.4,0.0,1,1),fill-opacity .1s cubic-bezier(0.4,0.0,1,1)',
  fill: hovered ? '#f00' : '#212121',
  fillOpacity: hovered ? 1 : 0.8,
}));
