import React from 'react';
import { Text, useTranslator } from '@eo-locale/react';
import Typography from '@mui/material/Typography';
import { classes } from '@guest-widgets/shared/src/classes';

export interface ReviewContentProps {
  author: string;
  createdAt: string;
  content: string;
}

export const ReviewContent = ({ author, createdAt, content }: ReviewContentProps) => {
  const translator = useTranslator();

  return (
    <>
      <Typography variant="body2" className={classes.details.reviews.review.body}>
        {content}
      </Typography>
      <Typography
        className={classes.details.reviews.review.caption}
        variant="caption"
        color="textSecondary"
      >
        <Text
          id="product-details.reviews.written"
          author={author}
          date={translator.formatDate(new Date(createdAt))}
        />
      </Typography>
    </>
  );
};
