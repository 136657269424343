import { register } from '@guest-widgets/core';
import { hasShadowDom } from '@guest-widgets/shared/src/utils/hasShadowDom';
import { widgetTagNames } from '@guest-widgets/shared/src/config';
import { checkLoadedAndRegister } from '@guest-widgets/shared/src/utils/singleLoad';

import { ProductWidgetWebComponent } from './ProductWidgetWebComponent';
import { initDom } from './initDom';
import { ProductPreviewWidgetWebComponent } from './ProductPreviewWidgetWebComponent';
import { customize as customizePreview } from './PreviewApp/customize';

(async () => {
  if (checkLoadedAndRegister('product')) return;

  register(
    ProductWidgetWebComponent,
    widgetTagNames.product,
    [
      'customer-code',
      'product',
      'locale',
      'disable-features',
      'enable-features',
      'styles-template-id',
      // This is an option set the viewport to something different than the window to be able to simulate different
      // device sizes without using the dev tools. Rendering the widget in an iframe would be the alternative
      'viewport-id',
      'widget-id',
      'configuration',
      'sub-id',
      'data-disable-font',
    ],
    hasShadowDom
  );

  register(
    ProductPreviewWidgetWebComponent,
    widgetTagNames.productPreview,
    [
      'locale',
      'disable-features',
      'enable-features',
      'styles-template-id',
      // This is an option set the viewport to something different than the window to be able to simulate different
      // device sizes without using the dev tools. Rendering the widget in an iframe would be the alternative
      'viewport-id',
      'configuration',
      'data-disable-font',
    ],
    hasShadowDom,
    customizePreview
  );

  initDom();
})();
