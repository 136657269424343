import {
  AddToCartData,
  RemoveFromCartData,
  SearchProductsData,
  ViewCartData,
  ViewProductData,
  PageViewData,
  BeginCheckoutData,
  PurchaseData,
} from '@guest-widgets/shared/src/utils/customerTracking/customerTrackingCreator';
import { CartItem } from '@guest-widgets/booking/src/App/contexts/apiContext/cart/cart';
import { SupportedCurrencies } from '@guest-widgets/booking/src/App/contexts/settingsContext/settings';
import { ListProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import {
  AddToCartDataLayer,
  BeginCheckoutDataLayer,
  CartProps,
  GA4Event,
  GA4Items,
  ItemArgs,
  PageViewDataLayer,
  PurchaseDataLayer,
  RemoveFromCartDataLayer,
  SearchProductDataLayer,
  ViewCartDataLayer,
  ViewEvent,
  ViewItemProps,
  ViewProductDataLayer,
  ViewProductListDataLayer,
} from './ga4Types';

export class GA4EventMapper {
  mapPageView = ({ title, virtualPageViewData }: PageViewData): PageViewDataLayer => {
    if (virtualPageViewData) {
      const { title, path } = virtualPageViewData;
      const location = window.location.origin + path;

      return {
        event: ViewEvent.virtualPage,
        page_title: title,
        page_location: location,
      };
    }

    return {
      event: ViewEvent.virtualPage,
      page_title: title!,
      page_location: location.origin,
      page_path: location.pathname,
      page_referrer: document.referrer,
    };
  };

  mapSearchProducts = ({ keyword }: SearchProductsData): SearchProductDataLayer => {
    return {
      event: ViewEvent.search,
      ecommerce: {
        search_term: keyword,
      },
    };
  };

  mapToViewProductList = (
    products: ListProductDto[],
    event: GA4Event
  ): ViewProductListDataLayer => {
    const items = products.map((product) => {
      const item = {
        id: product.id,
        name: product.title || '',
        brand: '', // not available
        category: '', // Not available in product or settings
        price: Number(product.price?.gross?.amount) || 0,
      };
      return this.mapGA4ViewItems(item);
    });

    return {
      event,
      ecommerce: {
        items: items,
      },
    };
  };

  mapViewProduct = (data: ViewProductData): ViewProductDataLayer => {
    const viewItem = {
      id: data.settings.productId,
      name: data.product.name || '',
      brand: data.settings.providerName || '',
      category: '', // Not available in product or settings
      price: data.product.price || 0,
    };

    return {
      event: GA4Event.viewProduct,
      ecommerce: {
        value: viewItem.price,
        currency: data.product.currency,
        items: [this.mapGA4ViewItems(viewItem)],
      },
    };
  };

  mapViewCart = ({ items, currency }: ViewCartData): ViewCartDataLayer => {
    const GA4itemsInfos = items.map((item) => {
      return this.getCartItemsInfo(item, currency);
    });

    const value = (GA4itemsInfos.reduce((acc, item) => acc + item.value, 0).toFixed(
      2
    ) as unknown) as number;

    return {
      event: GA4Event.viewCart,
      ecommerce: {
        value,
        currency,
        items: GA4itemsInfos.flatMap((item) => item.ga4_items),
      },
    };
  };

  mapAddToCart = ({ addedItem, currency }: AddToCartData): AddToCartDataLayer => {
    const itemsInfo = this.getCartItemsInfo(addedItem, currency);
    return {
      event: GA4Event.addToCart,
      ecommerce: {
        currency: itemsInfo.currency,
        value: itemsInfo.value,
        items: itemsInfo.ga4_items,
      },
    };
  };

  mapRemoveFromCart = ({ removedItem, currency }: RemoveFromCartData): RemoveFromCartDataLayer => {
    const itemsInfo = this.getCartItemsInfo(removedItem, currency);
    return {
      event: GA4Event.removeFromCart,
      ecommerce: {
        currency: itemsInfo.currency,
        value: itemsInfo.value,
        items: itemsInfo.ga4_items,
      },
    };
  };

  mapBeginCheckout = ({ items, currency }: BeginCheckoutData): BeginCheckoutDataLayer => {
    const GA4itemsInfos = items.map((item) => {
      return this.getCartItemsInfo(item, currency);
    });

    const value = (GA4itemsInfos.reduce((acc, item) => acc + item.value, 0).toFixed(
      2
    ) as unknown) as number;

    // TODO: there is only one field for discount code in GA4, so we are taking the first discount code from the items
    // this approach is correct until we allow in ui only one code to be applied
    const discount_code = items.find((item) => item.discount)?.discount?.code || '';

    return {
      event: GA4Event.checkout,
      ecommerce: {
        value,
        currency,
        coupon: discount_code,
        items: GA4itemsInfos.flatMap((item) => item.ga4_items),
      },
    };
  };

  mapPurchase = ({ bookingNumber, items, currency }: PurchaseData): PurchaseDataLayer => {
    const GA4itemsInfos = items.map((item) => {
      return this.getCartItemsInfo(item, currency);
    });

    const value = (GA4itemsInfos.reduce((acc, item) => acc + item.value, 0).toFixed(
      2
    ) as unknown) as number;
    const tax = (GA4itemsInfos.reduce(
      (acc, item) => acc + item.value_incl_tax - item.value,
      0
    ).toFixed(2) as unknown) as number;

    return {
      event: GA4Event.purchase,
      ecommerce: {
        transaction_id: bookingNumber,
        value,
        currency,
        tax,
        shipping: 0, // Not available
        items: GA4itemsInfos.flatMap((item) => item.ga4_items),
      },
    };
  };

  mapGA4Items = ({
    product,
    itemPrice,
    category,
    qty,
    variant,
    price_incl_tax,
    tax,
  }: ItemArgs): GA4Items => {
    const { productId, name, brand } = product;

    return {
      item_id: productId.toString(),
      item_name: name,
      item_brand: brand,
      item_category: category,
      item_variant: variant ?? '',
      quantity: qty,
      price: itemPrice ?? 0,
      tax: tax ?? 0,
      price_incl_tax: price_incl_tax ?? 0,
    };
  };

  getCartItemsInfo = (item: CartItem, currency: SupportedCurrencies): CartProps => {
    const qty = Object.values(item.guestTypes).reduce((sum, qty) => sum + qty, 0);

    const {
      product,
      guestTypes,
      end,
      start,
      price: {
        total: { amount: total },
      },
    } = item;

    const tax = item.price.tax?.amount || 0;
    const inclusiveTaxTotal = item.price.inclusiveTaxTotal?.amount || 0;
    const value = total - inclusiveTaxTotal;
    const value_incl_tax = total + tax;
    const totalTaxes = inclusiveTaxTotal + tax;

    return {
      currency,
      value,
      value_incl_tax,
      ga4_items: [
        ...Object.keys(guestTypes)
          .filter((guestTypeKey) => guestTypes[guestTypeKey] !== 0)
          .map((guestType) => {
            return this.mapGA4Items({
              product,
              itemPrice: Number((value / qty).toFixed(2)),
              category: guestType,
              qty: guestTypes[guestType],
              variant: `${start}-${end}`,
              price_incl_tax: Number((value_incl_tax / qty).toFixed(2)),
              tax: Number((totalTaxes / qty).toFixed(2)),
            });
          }),
      ],
    };
  };

  mapGA4ViewItems = (product: ViewItemProps): GA4Items => {
    return {
      item_id: product.id,
      item_name: product.name,
      item_brand: product.brand,
      item_category: product.category,
      item_variant: '',
      quantity: 0,
      price: product.price, //TODO not available in product
    };
  };
}
