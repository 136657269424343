import type { DurationDtoUnitEnum } from '@checkfront/guest-experience-api-api-client-javascript-axios';

export const durationMap: Record<DurationDtoUnitEnum, string> = {
  Minute: 'minute',
  Hour: 'hour',
  Day: 'day',
  Night: 'night',
  Week: 'week',
  Month: 'month',
  Year: 'year',
};

export const mapDurationToLocalString = (durationType: DurationDtoUnitEnum) =>
  durationMap[durationType];
