import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import type { DetailProductDto } from '@checkfront/guest-experience-api-api-client-javascript-axios/dist/api';
import { Loadable, useLoadable } from '@guest-widgets/shared/src/utils/loadable/loadable';
import { productApi } from '@guest-widgets/shared/src/apis/guestExperience';

import { useSettings } from './SettingsContext/settingsContext';

export interface ProductContext {
  product: Loadable<DetailProductDto>;
}

export const productContext = createContext<ProductContext>({} as ProductContext);

export const ProductProvider = ({ children }: PropsWithChildren<{}>) => {
  const { productId, customerCode, locale } = useSettings();
  const [product, loadProduct] = useLoadable(productApi.getProduct);

  useEffect(() => {
    loadProduct(productId, customerCode, locale);
  }, [productId, customerCode]);

  return <productContext.Provider value={{ product }}>{children}</productContext.Provider>;
};

export const useProduct = () => useContext(productContext);
