import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography, { TypographyProps } from '@mui/material/Typography';
import AccessTime from '@mui/icons-material/AccessTime';
import LocationOn from '@mui/icons-material/LocationOn';
import { Text } from '@eo-locale/react';
import type {
  LabelDto,
  RatingDto,
  DurationDto,
  Price,
} from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { LabelTypeDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';

import { useNumberFormatter } from '../../hooks/useNumberFormatter';
import { Rating } from '../Rating/Rating';
import { classes } from '../../classes';
import { LineClamp } from '../LineClamp';
import { DurationFrom } from '../DurationFrom';
import { calcCssSize } from '../../utils/themeCalculator';
import NoImageIcon from '../Icons/NoImageIcon';

import { Label } from './Label';
import { ProductItemFeature } from './features';

export interface ProductListItemProps {
  title: string;
  description?: string;
  price: Price;
  image: string;
  rating?: RatingDto; // value: float in [0, 1]
  location?: string;
  duration?: DurationDto[];
  small?: boolean;
  label?: LabelDto;
  isEnabled(feature: any): boolean;
  language?: string;
}

export const ProductListItem = ({
  title,
  description,
  price,
  image,
  rating,
  location,
  duration,
  small,
  label,
  isEnabled,
  language,
}: ProductListItemProps) => {
  const { formatPrice } = useNumberFormatter(language);
  const labelType = label?.type;
  const hasLabel = [LabelTypeDto.Bestseller, LabelTypeDto.New].includes(labelType!);
  const isInactive = [LabelTypeDto.SoldOut, LabelTypeDto.SaleEnded].includes(labelType!);

  const content = {
    title: (
      <Title small={small} className={classes.catalog.product.header}>
        {title}
      </Title>
    ),
    rating: rating && rating.count > 0 && isEnabled(ProductItemFeature.ProductRating) && (
      <Rating value={rating.value * 5} small={small} />
    ),
    description: description && !small && isEnabled(ProductItemFeature.ProductShowDescription) && (
      <Description
        className={classes.catalog.product.description}
        lines={4}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    ),
    shortInfo: (
      <ShortInfoContainer className={classes.catalog.product.shortInfo.root}>
        {duration && isEnabled(ProductItemFeature.ProductDuration) && duration.length >= 1 && (
          <TypographyInfoContainer
            variant="caption"
            className={classes.catalog.product.shortInfo.duration}
          >
            <AccessTime />
            <DurationFrom duration={duration} />
          </TypographyInfoContainer>
        )}
        {location && isEnabled(ProductItemFeature.ProductLocation) && (
          <TypographyInfoContainer
            variant="caption"
            className={classes.catalog.product.shortInfo.location}
          >
            <LocationOn /> {location}
          </TypographyInfoContainer>
        )}
      </ShortInfoContainer>
    ),
    price: isEnabled(ProductItemFeature.ProductPrice) && (
      <PriceContainer className={classes.catalog.product.price.root}>
        <ItemPriceFrom className={classes.catalog.product.price.label}>
          <Text id="product-list-item.price-from" />
        </ItemPriceFrom>
        <Typography variant="h5" className={classes.catalog.product.price.value}>
          {formatPrice(parseFloat(price.gross.amount), price.gross.currencyCode)}
        </Typography>
      </PriceContainer>
    ),
    button: isEnabled(ProductItemFeature.ProductButton) && (
      <Button
        className={classes.catalog.product.button}
        color="primary"
        variant="contained"
        disableElevation
        disableFocusRipple
      >
        <Text id={isInactive ? 'product-list-item.details' : 'product-list-item.book'} />
      </Button>
    ),
  };

  const defaultContent = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow="1"
      p={4}
      className={classes.catalog.product.content}
    >
      <div>
        {content.title}
        {content.description}
      </div>
      <div>
        {content.rating}
        {content.shortInfo}
        {(isEnabled(ProductItemFeature.ProductPrice) ||
          isEnabled(ProductItemFeature.ProductButton)) && (
          <Footer display="flex" justifyContent="space-between">
            {content.price}
            {content.button}
          </Footer>
        )}
      </div>
    </Box>
  );

  const reducedContent = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
      p={3}
      className={classes.catalog.product.content}
    >
      <SmallLeftContent pr={3}>
        {content.rating}
        {content.title}
      </SmallLeftContent>
      {content.price}
    </Box>
  );

  return (
    <Item className={classes.catalog.product.root}>
      {hasLabel && <Label label={label} />}
      <ImageContainer>
        {isInactive && labelType && (
          <InactiveLabel>
            <Text id={`product-list-item.labels.${labelType}`} />
          </InactiveLabel>
        )}
        {!!image ? (
          <Image
            src={image}
            alt={title}
            className={classes.catalog.product.image}
            isActive={!isInactive}
          />
        ) : (
          <ImagePlaceholder role="img">
            <NoImageIcon sx={{ fontSize: 132 }} />
          </ImagePlaceholder>
        )}
        <ImageSkeleton variant="rectangular" />
      </ImageContainer>
      {small ? reducedContent : defaultContent}
    </Item>
  );
};

type WithSize = { small?: boolean } & TypographyProps;

const GUEST_WIDGET_IMAGE_RATIO = '66%';

export const Item = styled('div')(({ theme: { border, shape, palette } }) => ({
  // Doing this with a pseudo for not having the border around the image
  '&:before': {
    content: '""',
    position: 'absolute',
    border: border,
    borderRadius: shape.borderRadius,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: palette.background.default,
}));

const TypographyInfoContainer = styled(Typography)({
  lineHeight: 1,
});

const Title = styled(({ small, ...rest }: WithSize) => (
  <Typography variant="h4" component="h4" {...rest} />
))(({ small, theme }) => ({
  zIndex: 1,
  whiteSpace: small ? 'nowrap' : 'normal',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: theme.palette.text.primary,
}));

const ImageContainer = styled('div')({
  overflow: 'hidden',
  display: 'block',
  position: 'relative',
  height: 0,
  width: '100%',
  paddingTop: GUEST_WIDGET_IMAGE_RATIO,
});

const ImageSkeleton = styled(Skeleton)({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
});

const ImagePlaceholder = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  background: '#F1F5F9',
});

const InactiveLabel = styled('div')(({ theme: { typography } }) => ({
  position: 'absolute',
  top: '40%',
  marginLeft: 'auto',
  marginRight: 'auto',
  left: 0,
  right: 0,
  textAlign: 'center',
  fontWeight: typography.fontWeightMedium,
  fontSize: calcCssSize(typography.fontSize, 2),
  zIndex: 1,
}));

type ImageProps = { isActive: boolean } & DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const Image = styled(({ isActive, ...rest }: ImageProps) => <img {...rest} />)(({ isActive }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: isActive ? '100%' : '25%',
  objectFit: 'cover',
}));

export const Footer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, -4, -4, -4),
  padding: theme.spacing(4),
  borderTop: theme.border,
}));

const ItemPriceFrom = styled('div')(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

const Description = styled(LineClamp)(({ theme }) => ({
  margin: theme.spacing(2, 0, 3),
  zIndex: 1,
  color: theme.palette.text.primary,
  fontSize: theme.typography.body2.fontSize,
}));

const SmallLeftContent = styled(Box)({
  maxWidth: '75%',
});

const ShortInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(4),
  fontWeight: 500,
  color: theme.palette.text.secondary,

  '& > *': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    marginRight: '1.25em',
  },

  '& > *:last-child': {
    marginLeft: 0,
  },

  '& > * > svg': {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
  },
}));

const PriceContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
}));
