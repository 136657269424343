import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { classes } from '@guest-widgets/shared/src/classes';

interface InformationItemProps {
  className?: string;
  icon: ReactNode;
  title: ReactNode;
  html?: string;
  content?: ReactNode;
}

export const InformationItem = ({
  className,
  icon,
  title,
  html,
  content,
}: InformationItemProps) => (
  <InformationPanel
    className={`grid ${classes.details.information.product.root} ${className || ''}`}
    display="grid"
    gridTemplateColumns="auto 1fr"
    alignItems="center"
    columnGap={4}
  >
    <Box component="span" lineHeight={0} className={classes.details.information.product.icon}>
      {icon}
    </Box>
    <Typography component="h4" variant="h4" className={classes.details.information.product.header}>
      {title}
    </Typography>
    {html ? (
      <Text
        className={classes.details.information.product.body}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    ) : (
      <Text className={classes.details.information.product.body}>{content}</Text>
    )}
  </InformationPanel>
);

const InformationPanel = styled(Box)(({ theme }) => ({
  '& > *': {
    color: theme.palette.text.primary,
  },
}));

const Text = styled('div')(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  gridColumn: 2,
  '.grid:not(:last-child) > &': {
    paddingBottom: theme.spacing(4),
    borderBottom: theme.border,
    marginBottom: theme.spacing(4),
  },
}));
