export interface Legacy {
  location: string;
  timestamp: number;
  payment_method: string;
  city: string;
  country: string;
  product_ids_txt: string;
  product_ids_cnt: number;
  product_ids_txt2: string;
}

export enum TrackingEventType {
  PAGE_VIEW = 'pageView',
  SEARCH_PRODUCTS = 'searchProducts',
  VIEW_PRODUCT_LIST = 'viewProductList',
  VIEW_PRODUCT = 'viewProduct',
  SELECT_PRODUCT = 'selectProduct',
  VIEW_CART = 'viewCart',
  ADD_TO_CART = 'addToCart',
  REMOVE_FROM_CART = 'removeFromCart',
  CHECKOUT = 'checkout',
  PURCHASE = 'purchase',
}
