import React, { createContext, PropsWithChildren, useContext, ReactNode } from 'react';
import { objectsMerge } from '@guest-widgets/shared/src/utils/objectsMerge';
import { useBackendSettings } from '@guest-widgets/shared/src/hooks/useBackendSettings';
import { SharedWidgetSettings } from '@guest-widgets/shared/src/types/featureSetting';
import { WidgetConfigurationDto } from '@checkfront/guest-widgets-editor-api-api-client-javascript-axios';
import { LanguageLocaleCode, formatLocaleForI18n } from '@guest-widgets/shared/src/i18n/i18n';
import { ErrorState, ErrorViews } from '@guest-widgets/shared/src/components/ErrorViews';
import { LocaleTranslator } from '@guest-widgets/shared/src/components/LocaleTranslator';

import { defaultLocale, languageMap } from '../../i18n/i18n';
import { ProductDetailsSkeleton } from '../ProductDetailsSkeleton/ProductDetailsSkeleton';

import { useQueryStringSettings } from './useQueryStringSettings';

export interface SettingsContext extends SharedWidgetSettings {
  productId: string;
}

export const settingsContext = createContext({} as SettingsContext);

type WidgetSettings = Partial<SettingsContext>;

export interface SettingsContextProps extends WidgetSettings {
  widgetId?: string;
}

export const SettingsProvider = ({
  children,
  widgetId,
  ...settingsFromProps
}: PropsWithChildren<SettingsContextProps>) => {
  const settingsFromQueryString = useQueryStringSettings();
  const settingsFromFrontend = objectsMerge<WidgetSettings>(
    settingsFromQueryString,
    settingsFromProps
  );

  const settingsFromBackend = useBackendSettings(widgetId, mapToWidgetSettings);

  if (!widgetId) {
    return render(children, settingsFromFrontend);
  }

  if (settingsFromBackend.isFailed) {
    return renderIsFailedError(settingsFromBackend.error);
  }

  if (!settingsFromBackend.isValid) {
    // While backend settings are loading we might have already necessary settings from widget props
    return render(children, settingsFromProps, true);
  }

  const mergedSettings = objectsMerge<WidgetSettings>(
    settingsFromFrontend,
    settingsFromBackend.value
  );

  return render(children, mergedSettings);
};

export const SettingsConsumer = settingsContext.Consumer;
export const useSettings = () => useContext(settingsContext);

const mapToWidgetSettings = ({
  configuration,
  settings,
}: WidgetConfigurationDto): WidgetSettings => ({
  ...settings,
  configuration,
});

const render = (children: ReactNode, settings: WidgetSettings, isLoading: boolean = false) => {
  const { customerCode, locale, productId, ...rest } = settings;

  if (!customerCode || !locale || !productId) {
    if (isLoading) return <ProductDetailsSkeleton />;
    return renderError('Required properties are missing in the widget settings', settings);
  }

  return (
    <settingsContext.Provider
      value={{
        ...rest,
        customerCode,
        locale: formatLocaleForI18n(locale) as LanguageLocaleCode,
        productId,
      }}
    >
      {children}
    </settingsContext.Provider>
  );
};

const renderError = (...logMessage: unknown[]) => {
  if (logMessage.length) console.error(...logMessage);
  return <ErrorViews type={ErrorState.UnableToLoad} onClick={() => window.location.reload()} />;
};

const renderIsFailedError = (...logMessage: unknown[]) => {
  if (logMessage.length) console.error(...logMessage);

  return (
    <LocaleTranslator
      languageMap={languageMap}
      defaultLocale={defaultLocale}
      includeSharedKeys={true}
    >
      <ErrorViews type={ErrorState.UnableToLoad} onClick={() => window.location.reload()} />
    </LocaleTranslator>
  );
};
