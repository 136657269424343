import {
  AddToCartData,
  PageViewData,
  ProductsData,
  RemoveFromCartData,
  Tag,
  ViewCartData,
  ViewProductData,
} from './customerTrackingCreator';
import { MatomoEventMapper } from './mappers/matomo/matomoEventMapper';

declare global {
  interface Window {
    _paq: any[];
    matomoScriptLoaded: boolean;
  }
}

export class Matomo implements Tag {
  private matomoEventMapper: MatomoEventMapper;
  private static instance: Matomo;
  private scriptLoaded: boolean = false;

  private constructor() {
    this.matomoEventMapper = new MatomoEventMapper();
    const matomoUrl = import.meta.env.VITE_MATOMO_INSTANCE_URL;
    const matomoSiteId = import.meta.env.VITE_MATOMO_SITE_ID ?? 1;
    if (!this.scriptLoaded && !window.matomoScriptLoaded && matomoUrl && matomoSiteId)
      this.loadScript(matomoUrl, matomoSiteId);
  }
  isAvailable() {
    return typeof window !== 'undefined' && !!window._paq;
  }

  private pushEvent(eventData: string[]) {
    window._paq = window._paq || [];
    window._paq.push(eventData);
  }
  private loadScript(matomoUrl: string, siteId: string) {
    if (this.scriptLoaded || window.matomoScriptLoaded) return;

    (() => {
      const scriptUrl = `${matomoUrl}matomo.js`;
      const trackerUrl = `${matomoUrl}matomo.php`;

      this.pushEvent(['setTrackerUrl', trackerUrl]);
      this.pushEvent(['setSiteId', siteId]);
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];

      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = scriptUrl;
      s?.parentNode?.insertBefore(g, s);
    })();

    this.scriptLoaded = true;
    window.matomoScriptLoaded = true;
  }

  public static getInstance(): Matomo {
    if (!Matomo.instance) {
      Matomo.instance = new Matomo();
    }
    return Matomo.instance;
  }

  pageView({ title, virtualPageViewData }: PageViewData): void {
    const data = this.matomoEventMapper.mapPageView({ title, virtualPageViewData });

    this.pushEvent(['deleteCustomVariables', 'page']);
    this.pushEvent(['setCustomUrl', data.page_location]);
    this.pushEvent(['setDocumentTitle', data.page_title]);
    this.pushEvent(['setReferrerUrl', data.page_referrer]);
    this.pushEvent(['trackPageView']);
  }
  searchProducts(keyword: string) {
    // Implement searchProducts method
  }
  selectProduct(selectProductData: ProductsData) {
    // Implement searchProducts method
  }
  viewCart(viewCartData: ViewCartData) {
    // Implement searchProducts method
  }

  viewProductList(viewProductListData: ProductsData) {
    // Implement viewProductList method
  }

  viewProduct(data: ViewProductData) {
    // Implement viewProduct method
  }

  addToCart(addToCartData: AddToCartData) {
    // Implement addToCart method
  }

  removeFromCart(removeFromCartData: RemoveFromCartData) {
    // Implement removeFromCart method
  }

  checkout(checkoutData: any) {
    // Implement checkout method
  }

  purchase(purchaseData: any) {
    // Implement purchase method
  }
}
