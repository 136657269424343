import React from 'react';
import { Text } from '@eo-locale/react';

export interface CategoryTitleProps {
  label: string;
}

export const CategoryTitle = ({ label }: CategoryTitleProps) =>
  label in keyMap ? (
    <Text id={`product-details.reviews.rating-categories.${keyMap[label]}`} />
  ) : (
    <>{label}</>
  );

const keyMap: Record<string, string> = {
  'Overall rating': 'overall',
  Value: 'value',
  'Fun factor': 'fun-factor',
};
