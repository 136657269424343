import { useEffect } from 'react';
import { WidgetConfigurationDto } from '@checkfront/guest-widgets-editor-api-api-client-javascript-axios/api';

import { useLoadable } from '../utils/loadable/loadable';
import { widgetEditorApi } from '../apis/widgetEditor';

export const useBackendSettings = <V = WidgetConfigurationDto>(
  widgetId: string | undefined,
  selectState: (configuration: WidgetConfigurationDto) => V
) => {
  const [settingsAndConfigs, loadSettings] = useLoadable(
    (widgetId) => widgetEditorApi.getWidgetConfiguration({ id: widgetId }),
    ({ data }) => selectState(mapToWidgetSettings(data))
  );

  useEffect(() => {
    if (widgetId) {
      loadSettings(widgetId);
    }
  }, []);

  return settingsAndConfigs;
};

const mapToWidgetSettings = (configuration: any): WidgetConfigurationDto => {
  return {
    settings: {
      ...configuration.settings,
      customerCode: configuration.settings.companyCode,
    },
    configuration: configuration.configuration,
  };
};
