import React from 'react';
import type { ImageDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { styled } from '@mui/material/styles';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { classes } from '@guest-widgets/shared/src/classes';

import { getPreviewUrl } from '../youtubeUtils';

interface ThumbnailsItemProps {
  item: string | ImageDto;
  isSelected: Boolean;
  onClick: () => void;
  width: number;
  height: number;
}

export const ThumbnailsItem = ({ item, isSelected, onClick, ...rest }: ThumbnailsItemProps) => {
  const props = { onClick: !isSelected ? onClick : undefined, isSelected, ...rest };

  if (typeof item === 'string') {
    return (
      <Item
        {...props}
        imageSrc={getPreviewUrl(item)}
        className={classes.details.gallery.thumbnails.thumbnail}
      >
        <StyledYouTube />
        {isSelected && <Selected />}
      </Item>
    );
  }

  return (
    <Item
      {...props}
      imageSrc={item.thumbnailUrl}
      className={classes.details.gallery.thumbnails.thumbnail}
    >
      {isSelected && <Selected />}
    </Item>
  );
};

type ItemProp = {
  isSelected: Boolean;
  imageSrc: String;
  width: number;
  height: number;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledYouTube = styled(YouTubeIcon)({
  position: 'absolute',
  left: 'calc(50% - 10px)',
  top: 'calc(50% - 10px)',
});

const Item = styled(({ isSelected, imageSrc, ...rest }: ItemProp) => <div {...rest} />)(
  ({ isSelected, imageSrc, width, height }) => ({
    position: 'relative',
    cursor: !isSelected ? 'pointer' : undefined,
    height,
    width,
    backgroundImage: `url("${imageSrc}")`,
    backgroundSize: 'cover',
  })
);

const Selected = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
  opacity: 0.5,
  top: 0,
}));
