import { SupportedCurrencies } from '@guest-widgets/booking/src/App/contexts/settingsContext/settings';

export interface PageViewDataPaq {
  page_title: string;
  page_location: string;
  page_referrer: string;
}

interface CommonFields {
  productSKU: string;
  name: string;
  category: string;
  price: number;
}
export interface ViewProductPaq extends CommonFields {
  [key: string]: string | number;
}
export interface AddToCartDataPaq extends CommonFields {
  quantity: number;
}
export interface ProductListPaq {
  item_list_id?: string;
  item_list_name?: string;
  items: CommonFields[];
}

export interface ViewCartPaq {
  value: number;
  currency: SupportedCurrencies;
  items: CommonFields[];
}

export enum MatomoEvents {
  trackEvent = 'trackEvent',
  deleteCustomVariables = 'deleteCustomVariables',
  setCustomUrl = 'setCustomUrl',
  setDocumentTitle = 'setDocumentTitle',
  setReferrerUrl = 'setReferrerUrl',
  trackPageView = 'trackPageView',
  addEcommerceItem = 'addEcommerceItem',
  trackEcommerceCartUpdate = 'trackEcommerceCartUpdate',
  removeEcommerceItem = 'removeEcommerceItem',
  setCustomDimension = 'setCustomDimension',
  deleteCustomDimension = 'deleteCustomDimension',
}

export type CustomMatomoDimension = {
  idcustomdimension: string;
  idsite: string;
  name: string;
  index: string;
  scope: 'action' | 'visit';
  active: boolean;
  extractions?: {
    dimension: string;
    pattern: string;
  }[];
  case_sensitive: boolean;
};
