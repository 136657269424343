import { useCallback, useState } from 'react';

enum LoadableState {
  IsEmpty = 'IsEmpty',
  IsLoading = 'IsLoading',
  IsValid = 'IsValid',
  IsFailed = 'IsFailed',
}

export class Loadable<V, E = any> {
  readonly value!: V;
  readonly error!: E;

  static createEmpty<V, E = any>() {
    return new Loadable<V, E>(LoadableState.IsEmpty);
  }

  static createLoading<V, E = any>(oldValue?: V) {
    return new Loadable<V, E>(LoadableState.IsLoading, oldValue);
  }

  static createValid<V, E = any>(value: V) {
    return new Loadable<V, E>(LoadableState.IsValid, value);
  }

  static createFailed<E, V = any>(error?: E) {
    return new Loadable<V, E>(LoadableState.IsFailed, error);
  }

  public map<V2, E2 = any>(
    mapValue: (values: V) => V2,
    mapError?: (error: E) => E2
  ): Loadable<V2, E2> {
    let payload: V2 | E2 | E | undefined = undefined;

    if (this.state === LoadableState.IsValid || this.state === LoadableState.IsLoading) {
      payload = this.value && mapValue(this.value);
    }

    if (this.state === LoadableState.IsFailed) {
      payload = mapError ? mapError(this.error) : ((this.error as unknown) as E2);
    }

    return new Loadable<V2, E2>(this.state, payload);
  }

  get isEmpty() {
    return this.state === LoadableState.IsEmpty;
  }

  get isLoading() {
    return this.state === LoadableState.IsLoading;
  }

  get isValid() {
    return this.state === LoadableState.IsValid;
  }

  get isFailed() {
    return this.state === LoadableState.IsFailed;
  }

  private constructor(public readonly state: LoadableState, payload?: V | E) {
    if (this.state === LoadableState.IsValid || this.state === LoadableState.IsLoading) {
      this.value = payload as V;
    }

    if (this.state === LoadableState.IsFailed) {
      this.error = payload as E;
    }
  }
}

type Action<P extends any[], R> = (...params: P) => Promise<R>;
type Mapped<A> = A extends (...params: infer P) => any ? (...params: P) => void : never;

export function useLoadable<R, V = R, P extends any[] = any[], E = any>(
  asyncAction: Action<P, R>,
  selectState?: (result: R) => V
): [Loadable<V, E>, Mapped<Action<P, V>>, () => void] {
  const [state, setState] = useState(Loadable.createEmpty<V, E>());

  const action = async (...params: P) => {
    setState(Loadable.createLoading(state.value));
    try {
      const result = await asyncAction(...params);
      setState(Loadable.createValid((selectState ? selectState(result) : result) as V));
    } catch (e: any) {
      setState(Loadable.createFailed(e));
    }
  };

  const reset = () => setState(Loadable.createEmpty());

  return [state, action, reset];
}
