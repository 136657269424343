import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';

import { config } from '../../config';

import { QueryLink } from './QueryLink';

const { productIdQueryKey } = config;

interface ProductLinkProps {
  id: string;
}

export const ProductLink = ({ id, children }: PropsWithChildren<ProductLinkProps>) => (
  <StyledQueryLink name={productIdQueryKey} value={id}>
    {children}
  </StyledQueryLink>
);

const StyledQueryLink = styled(QueryLink)(({ theme: { transitions, shape } }) => ({
  borderRadius: shape.borderRadius,
  display: 'block',
  height: '100%',
  textDecoration: 'none',
  transition: transitions.create(['transform', 'box-shadow'], {
    duration: transitions.duration.shorter,
  }),
  '&:hover, &:focus': {
    transform: 'scale(1.012)',
    boxShadow: `0 0 24px 0 rgba(0, 0, 0, 0.1)`,
  },
  '&:active': {
    transform: 'scale(1.006)',
    boxShadow: `0 0 12px 0 rgba(0, 0, 0, 0.1)`,
  },
}));
