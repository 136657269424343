import React, { useState, useEffect, createRef } from 'react';
import { useTranslator } from '@eo-locale/react';
import type { DetailProductLocationDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { classes } from '@guest-widgets/shared/src/classes';
import { GoogleMapsApiLoader } from '@guest-widgets/shared/src/components/GoogleMaps/GoogleMapsApiLoader';
import { useGoogleMaps } from '@guest-widgets/shared/src/components/GoogleMaps/useGoogleMaps';
import { styled } from '@guest-widgets/core';

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY as string;

export interface MapProps {
  location: DetailProductLocationDto;
}

export const Map = ({ location: { geolocation, mapImageUrl } }: MapProps) => {
  const { language, translate } = useTranslator();
  const mapRef = createRef<HTMLDivElement>();
  const googleMaps = useGoogleMaps();
  const [isMapActive, setIsMapActive] = useState(false);

  useEffect(() => {
    initMap();
  }, [googleMaps, isMapActive]);

  function initMap() {
    if (!googleMaps || !isMapActive || !mapRef.current || !geolocation) {
      return;
    }

    const geocode = {
      lat: parseFloat(geolocation.latitude),
      lng: parseFloat(geolocation.longitude),
    };
    const map = new googleMaps.Map(mapRef.current, {
      zoom: 14,
      center: geocode,
    });

    new googleMaps.Marker({
      position: geocode,
      map: map,
    });
  }

  if (!isMapActive) {
    return (
      <MapImage
        src={mapImageUrl}
        className={classes.details.location.map.image}
        onClick={() => setIsMapActive(true)}
        title={translate('product-details.location.map')}
      />
    );
  }

  return (
    <>
      <GoogleMapsApiLoader apiKey={GOOGLE_API_KEY} language={language} />
      <MapContainer className={classes.details.location.map.interactive} ref={mapRef} />
    </>
  );
};

const MapImage = styled('img')(({ theme, widgetArea }) => ({
  cursor: 'pointer',
  marginBottom: widgetArea.isLarge ? 0 : theme.spacing(4),
}));

const MapContainer = styled('div')(({ theme, widgetArea }) => ({
  height: 200,
  color: theme.palette.common.white,
  border: 'solid 1px',
  marginBottom: widgetArea.isLarge ? 0 : theme.spacing(4),
}));
