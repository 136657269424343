import React, { ReactNode, useEffect, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Chevron from '@mui/icons-material/ChevronRight';
import { WithTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useWidget, styled } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import { usePrevious } from '@guest-widgets/shared/src/hooks/usePrevious';

// noinspection ES6PreferShortImport
import { Drawer } from '../Drawer/Drawer';

interface ExpandableProps {
  className?: string;
  closedContent: ReactNode;
  title: ReactNode;
  children: ReactNode;
  isInitiallyOpen?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
}

const timeout = 300;

/**
 * This is a component which adopts according to the container size
 * For mobile it opens a sheet, for tablet a drawer and for desktop it is a collapsable section
 */
export const Expandable = ({
  className,
  isInitiallyOpen,
  closedContent,
  children,
  title,
  ...borders
}: ExpandableProps) => {
  const [isOpen, setIsOpen] = useState(!!isInitiallyOpen);
  const { viewportArea } = useWidget();

  const isLargeViewport = viewportArea.isLarge;

  const wasLargeViewport = usePrevious(isLargeViewport);

  const layoutHasChanged = wasLargeViewport !== isLargeViewport;

  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  useEffect(() => {
    if (isLargeViewport) return;
    setIsOpen(false);
  }, [isLargeViewport]);

  const expandedContent = isLargeViewport ? (
    <CollapseWrapper className={classes.details.collapsible.content.open}>
      <Collapse
        in={isOpen}
        timeout={timeout}
        style={{ transitionDelay: isOpen ? `${timeout / 2}ms` : '0ms' }}
      >
        <div>{children}</div>
      </Collapse>
    </CollapseWrapper>
  ) : (
    <Drawer isOpen={isOpen && !layoutHasChanged} title={title} onClose={close}>
      <DescriptionText>{children}</DescriptionText>
    </Drawer>
  );

  const closedContentInner = isLargeViewport ? (
    <Fade
      in={!isOpen}
      timeout={timeout}
      style={{ transitionDelay: isOpen ? '0ms' : `${timeout / 2}ms` }}
    >
      <div>{closedContent}</div>
    </Fade>
  ) : (
    closedContent
  );

  return (
    <StyledCollapse
      {...borders}
      className={`${classes.details.collapsible.root} ${className || ''}`}
    >
      <Header onClick={toggle} className={classes.details.collapsible.header}>
        <Title variant="h2">{title}</Title>
        <Arrow isRotating={isLargeViewport} isPointingUpward={!isOpen} />
      </Header>
      <Box
        display="flex"
        flexWrap="nowrap"
        pt={2}
        pb={4}
        className={classes.details.collapsible.content.root}
      >
        <ClosedContent className={classes.details.collapsible.content.closed}>
          {closedContentInner}
        </ClosedContent>
        {expandedContent}
      </Box>
    </StyledCollapse>
  );
};

type StyledCollapseProps = { borderTop?: boolean; borderBottom?: boolean } & WithTheme;

const StyledCollapse = styled('div')(({ theme, borderTop, borderBottom }: StyledCollapseProps) => ({
  paddingTop: theme.spacing(4),
  borderTop: borderTop ? theme.border : undefined,
  borderBottom: borderBottom ? theme.border : undefined,
}));

const Title = styled(Typography)(({ theme: { typography }, widgetArea }) => ({
  fontSize: widgetArea.basedOnSize(
    typography.h4.fontSize,
    typography.h4.fontSize,
    typography.h2.fontSize
  ),
}));

const Header = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

interface ArrowProps {
  isPointingUpward: boolean;
  isRotating: boolean;
}

const Arrow = styled(Chevron)(
  ({ theme, isPointingUpward, isRotating }: ArrowProps & WithTheme) => ({
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    transition: isRotating ? theme.transitions.create('transform') : undefined,
    transform: isRotating ? `rotate(${isPointingUpward ? '90deg' : '-90deg'})` : undefined,
  })
);

const Content = styled('div')({
  width: '100%',
  flex: 'none',
});

const ClosedContent = styled(Content)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.text.secondary,
}));

const CollapseWrapper = styled(Content)(() => ({
  marginLeft: '-100%',
}));

const DescriptionText = styled('div')(({ theme: { spacing, typography }, widgetArea }) => ({
  fontSize: typography.body2.fontSize,
  fontFamily: typography.body2.fontFamily,
  lineHeight: typography.body2.lineHeight,
  paddingTop: spacing(widgetArea.basedOnSize(2, 2, 0)),
  '& a:focus, & a:hover, & a:active, & a:-webkit-any-link': {
    color: 'inherit',
    textDecoration: 'none',
    outline: 'none',
  },
}));
