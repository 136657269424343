import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import { useWidget } from '@guest-widgets/core';

import { Outer, Inner } from '../ProductDetails/Reviews/ReviewsHorizontalPreview';

export const ReviewsSkeleton = () => {
  const { widgetArea } = useWidget();

  return (
    <Container>
      <Skeleton variant="text" width={200} height={50} />
      <Skeleton variant="rectangular" width="100%" height={50} />
      {widgetArea.isLarge && (
        <ReviewListLarge>
          <Skeleton variant="rectangular" width="100%" height={120} />
          <Skeleton variant="rectangular" width="100%" height={120} />
          <Skeleton variant="rectangular" width="100%" height={120} />
        </ReviewListLarge>
      )}
      {!widgetArea.isLarge && (
        <Outer>
          <Inner>
            <ReviewSmall variant="rectangular" width={288} height={288} />
            <ReviewSmall variant="rectangular" width={288} height={288} />
            <ReviewSmall variant="rectangular" width={288} height={288} />
          </Inner>
        </Outer>
      )}
      <AllReviewButton
        variant="rectangular"
        width={widgetArea.isSmall ? '100%' : '30%'}
        height={30}
      />
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const ReviewListLarge = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),

  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));

const ReviewSmall = styled(Skeleton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const AllReviewButton = styled(Skeleton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  float: 'right',
  marginBottom: theme.spacing(3),
}));
