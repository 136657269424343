import React, { PropsWithChildren } from 'react';
import { LocaleTranslator } from '@guest-widgets/shared/src/components/LocaleTranslator';

import { defaultLocale, languageMap } from '../i18n/i18n';

import { useSettings } from './SettingsContext/settingsContext';

export const LocalizationProvider = ({ children }: PropsWithChildren<{}>) => {
  const { locale } = useSettings();
  return (
    <LocaleTranslator languageMap={languageMap} locale={locale} defaultLocale={defaultLocale}>
      {children}
    </LocaleTranslator>
  );
};
