import { GA4EventMapper } from './mappers/ga4/ga4EventsMapper';
import {
  AddToCartData,
  BeginCheckoutData,
  PageViewData,
  ProductsData,
  PurchaseData,
  RemoveFromCartData,
  Tag,
  ViewCartData,
  ViewProductData,
} from './customerTrackingCreator';
import {
  PageViewDataLayer,
  SearchProductDataLayer,
  ViewProductListDataLayer,
  ViewProductDataLayer,
  AddToCartDataLayer,
  RemoveFromCartDataLayer,
  BeginCheckoutDataLayer,
  GA4Event,
  PurchaseDataLayer,
} from './mappers/ga4/ga4Types';

declare global {
  interface Window {
    dataLayer: any[] | undefined;
  }
}

export class GTM implements Tag {
  private ga4EventMapper: GA4EventMapper;

  constructor(ga4EventMapper: GA4EventMapper) {
    this.ga4EventMapper = ga4EventMapper;
  }

  isAvailable() {
    return typeof window !== 'undefined' && !!window.dataLayer;
  }

  private pushEvent(
    gtmEvent:
      | PageViewDataLayer
      | SearchProductDataLayer
      | ViewProductListDataLayer
      | ViewProductListDataLayer
      | ViewProductDataLayer
      | AddToCartDataLayer
      | RemoveFromCartDataLayer
      | BeginCheckoutDataLayer
      | PurchaseDataLayer
  ) {
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push(gtmEvent);
  }

  pageView({ title, virtualPageViewData }: PageViewData) {
    const gtmEvent = this.ga4EventMapper.mapPageView({ title, virtualPageViewData });
    this.pushEvent(gtmEvent);
  }

  searchProducts(keyword: string) {
    const gtmEvent = this.ga4EventMapper.mapSearchProducts({ keyword });
    this.pushEvent(gtmEvent);
  }

  selectProduct(selectProductData: ProductsData) {
    const gtmEvent = this.ga4EventMapper.mapToViewProductList(
      selectProductData.products,
      GA4Event.selectProduct
    );
    this.pushEvent(gtmEvent);
  }

  viewProductList(viewProductListData: ProductsData) {
    const gtmEvent = this.ga4EventMapper.mapToViewProductList(
      viewProductListData.products,
      GA4Event.viewProductList
    );
    this.pushEvent(gtmEvent);
  }

  viewProduct(data: ViewProductData) {
    const gtmEvent = this.ga4EventMapper.mapViewProduct(data);
    this.pushEvent(gtmEvent);
  }
  viewCart(data: ViewCartData) {
    const gtmEvent = this.ga4EventMapper.mapViewCart(data);
    this.pushEvent(gtmEvent);
  }

  addToCart(cartData: AddToCartData) {
    const gtmEvent = this.ga4EventMapper.mapAddToCart(cartData);
    this.pushEvent(gtmEvent);
  }

  removeFromCart(cartData: RemoveFromCartData) {
    const gtmEvent = this.ga4EventMapper.mapRemoveFromCart(cartData);
    this.pushEvent(gtmEvent);
  }

  checkout(checkoutData: BeginCheckoutData) {
    const gtmEvent = this.ga4EventMapper.mapBeginCheckout(checkoutData);
    this.pushEvent(gtmEvent);
  }

  purchase(purchaseData: PurchaseData) {
    const gtmEvent = this.ga4EventMapper.mapPurchase(purchaseData);
    this.pushEvent(gtmEvent);
  }
}
