import React from 'react';
import Chip from '@mui/material/Chip';
import { Text } from '@eo-locale/react';
import { classes } from '@guest-widgets/shared/src/classes';
import { Covid19Icon } from '@guest-widgets/shared/src/components/Icons/Covid19Icon';
import { styled } from '@guest-widgets/core';

import { useProduct } from '../../productContext';

export const Covid19 = () => {
  const {
    product: { isValid, value },
  } = useProduct();

  return isValid &&
    value.information &&
    value.information.covid19Safety &&
    value.information.covid19Safety.body === '1' ? (
    <StyledChip
      className={classes.details.labels.covid19}
      icon={<Covid19Icon />}
      label={<Text id="product-details.information.covid-19-safety.title" />}
    />
  ) : null;
};

const StyledChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.info.main,
  '& svg': {
    color: theme.palette.text.primary,
  },
}));
